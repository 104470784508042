@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;600;700;800&family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("./ionicons.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.5.0/css/all.css");

/* X-Large devices (large desktops, less than 1400px) */

@media (max-width: 1699.98px) {
	.shadow-path {
		right: -300px;
	}
}

@media (max-width: 1499.98px) {
	.shadow-path {
		right: -400px;
	}
	.left-text {
		left: -90%;
		top: 90px;
	}
	.right-text {
		right: -98%;
		bottom: 30px;
	}
	.left-text p,
	.right-text p {
		font-size: 36px;
	}
	.additional-gif-card .notification-img {
		min-width: 805px;
	}
	.additional-gif-card {
		min-height: 750px;
	}
	.testimonal_section {
		padding: 80px 0 90px;
	}
	.how-work {
		padding: 230px 0 110px;
		margin-top: -110px;
	}
	#notification-email {
		padding: 120px 0 170px;
	}
	#performance-gitCard {
		padding: 140px 0 0;
	}
	.dashboard-performance {
		padding: 80px 0;
	}
	.gifting_digitally {
		padding: 150px 0 160px;
	}
}

@media (max-width: 1366px) {
	.shadow-path {
		right: -400px;
	}
	.banner-shape .sky-shape {
		width: 1350px;
		left: 42%;
		top: -230px;
		height: 520px;
		z-index: -1;
		background-color: #1B3D9A;
		border-radius: 50px;
		transform: rotate(132deg);
	}
	.rtl .banner-shape .sky-shape {
		right: 42%;
	}
	.banner-shape .yellow-shape {
		width: 1350px;
		left: 58%;
		top: -340px;
		height: 520px;
		z-index: -2;
		background-color: #FFDD1C;
		border-radius: 50px;
		transform: rotate(132deg);
	}
	.rtl .banner-shape .yellow-shape{
		right: 58%;
	}
	.additional-gif-card .notification-img {
		min-width: 760px;
	}
	.additional-gif-card {
		min-height: 700px;
	}
}

@media (max-width: 1299.98px) {
	.dashboard-performance-img {
		max-width: 500px;
	}
	#choose-gitCard .card {
		margin: 0 0 15px;
	}

	.rtl .header-btn button{
		padding: 8px 14px;
		font-size: 16px;
		margin-right: 0;
	}
	.rtl .header-btn button + button{
		margin-right: 8px;
	}
	.additional-gif-card .notification-img {
		min-width: 680px;
	}
	.additional-gif-card {
		min-height: 600px;
	}

}


/* Large devices (desktops, less than 1200px) */

@media (max-width: 1199.98px) {
	.btn {
		padding: 10px 26px;
	}
	.banner-shape .sky-shape {
		left: 44%;
		top: -290px;
	}
	.rtl .banner-shape .sky-shape {
		right: 44%;
	}
	.contact-info h5 {
		font-size: 14px;
	}
	.set-email button {
		min-width: 150px;
		font-size: 18px;
		padding: 10px;
	}
	.success-note {
		padding: 0 !important;
	}
	.navbar-brand {
		max-width: 230px;
	}
	.how-work-step {
		padding: 20px;
	}
	.how-work-step .step-icon {
		max-width: 100px;
		height: 100px;
		min-width: 100px;
		min-height: 100px;
	}
	.how-work-step .step-icon img {
		max-width: 40px;
	}
	.how-work-step p {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 0;
	}
	.step-number span {
		font-size: 100px;
	}
	.navbar .navbar-nav .nav-link {
		padding: 10px 8px;
		font-size: 17px;
	}
	.rtl .navbar .navbar-nav .nav-link {
		padding: 10px 7px;
		font-size: 16px;
	}
	.how-work .work-it-title h3,
	.choose-gitCard-title h3,
	.instant-title h3,
	#faq-gift-card h3 {
		font-size: 34px;
	}
	.design-git-card .work-it-title h3 {
		font-size: 34px;
		max-width: 294px;
		margin-top: 16px;
		margin-bottom: 40px;
	}
	.design-git-card .personalized-title h3 {
		font-size: 34px;
	}

	.shadow-path {
		position: absolute;
		right: -400px;
		top: 60%;
		max-width: 973px;
		transform: translateY(-50%);
	}
	.how-work .work-it-title .btn,
	.design-git-card .work-it-title .btn,
	.design-git-card .personalized-title .btn {
		font-size: 18px;
		margin-top: 5px;
		margin-bottom: 0;
		padding: 12px 24px;
	}
	.instant-notification-title {
		max-width: 400px;
		min-height: 358px;
		margin-right: 0;
	}
	.notification-img {
		max-width: 550px;
	}
	#choose-gitCard .card {
		border-radius: 20px;
		border-bottom: 8px solid #A4D5F3;
		padding: 36px 20px 30px;
		height: 100%;
	}
	#choose-gitCard .card .choose-icon {
		max-width: 140px;
		margin-bottom: 26px;
	}
	#choose-gitCard .card h3 {
		font-size: 24px;
		margin-bottom: 20px;
	}
	#choose-gitCard .card p {
		font-size: 16px;
		margin-bottom: 20px;
		padding: 0 16px;
	}
	#choose-gitCard .card .api-img {
		max-width: 108px;
	}
	.dashboard-performance-img {
		max-width: 420px;
	}
	.left-text {
		left: -60%;
		top: 90px;
	}
	.right-text {
		right: -70%;
		bottom: 30px;
	}
	.left-text p,
	.right-text p {
		font-size: 28px;
	}
	.rtl .navbar-brand {
		max-width: 260px;
	}
	.rtl .header-btn button {
		font-size: 15px;
	}
	.rtl .header-btn button + button {
		margin-right: 4px;
	}
	.additional-gif-card .notification-img {
		min-width: 580px;
	}
	.additional-gif-card {
		min-height: 540px;
	}
}



@media only screen and (max-width: 1025px) {

	.customer_stats {
		margin-top: -95px;
	}
	.customer_stat_card {
		min-height: 250px;
	}
	.testimonal_section {
		padding: 40px 0 40px;
	}
	.trusted-partners {
		padding: 30px 40px;
	}
	.how-work {
		padding: 150px 0 50px;
		margin-top: -80px;
	}
	.show-m-view{
		margin-top: 30px;
	}
	#notification-email {
		padding: 60px 0 80px;
	}
	#performance-gitCard, .dashboard-performance {
		padding: 60px 0;
	}
	.gifting_digitally {
		padding: 70px 0;
	}
}


/* Medium devices (tablets, less than 992px) */

@media (max-width: 1024.98px) {
	header .navbar {
		top: 20px;
	}
	.shadow-path {
		position: absolute;
		right: -450px;
		top: 60%;
		max-width: 940px;
		transform: translateY(-50%);
	}
	.instant-title h3 {
		font-size: 34px;
		margin-bottom: 30px;
	}

	.personalized-title{
		margin-left: 70px;
	}
	.rtl .personalized-title{
		margin-right: 70px;
	}
	

	#feedback .feedback-field .form-control {
		height: calc(3em + 0.75rem + -6px);
	}
	.set-email p , .contact-numbers p{
		font-size: 13px !important;
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.navbar .navbar-nav .nav-link{
		font-size: 16px;
		padding: 10px 4px;
	}
}

@media (max-width: 991.98px) {
	.container {
		max-width: 100%;
	}
	header .navbar {
		top: 10px;
	}
	.rtl .header-btn {
		margin-left: 8px;
	}
	.header-btn button {
		padding-top: 8px;
		padding-bottom: 8px;
		margin-right: 8px;
		font-size: 17px;
		font-weight: 700;
	}
	.navbar-toggler {
		/* margin-left: auto; */
		margin-right: 8px;
		margin-left: 8px;
		padding: 0;
		border: 0;
	}
	.navbar-collapse {
		position: absolute;
		left: 0;
		right: 0;
		top: 100%;
		background-color: #fff;
		box-shadow: 0px 3px 5px rgb(0 0 0 / 10%);
		height: 100vh;
		width: 200px;
	}
	.navbar-nav {
		max-width: 750px;
		margin: 0 auto;
	}
	.banner-shape .sky-shape {
		left: 14%;
		top: -300px;
	}
	.rtl .banner-shape .sky-shape {
		right: 14%;
	}
	.banner-shape .yellow-shape {
		left: 35%;
		top: -397px;
	}
	.rtl .banner-shape .yellow-shape {
		right: 35%;
	}

	.testimonial_wrapper .swiper-container {
		max-height: 230px;
	}
	.testimonial_image {
		text-align: right;
		padding-right: 50px;
	}
	.rtl .testimonial_image {
		padding-left: 50px;
	}
	/* .testimonial_pagination {
		top: 20px;
	} */
	/* .rtl .testimonial_wrapper .swiper-container {
		direction: ltr;
	} */
	.rtl .testimonial_pagination::after {
		right:8.5px;
	}
	.rtl .testimonial_pagination .swiper-pagination-h {
		transform: translateX(-15%);
	}
	.language-select{
		margin-top: 2px;
	}
	.testimonial_content {
		max-width: unset;
		margin-left: 30px;
	}
	.testimonial_content .testimonial_info h5 {
		font-size: 15px;
	}
	.testimonial_content .testimonial_info label {
		font-size: 14px;
	}
	.heading * {
		line-height: 36px;
	}
	.heading h2 {
		font-size: 26px;
	}
	.heading h3 {
		font-size: 24px;
	}
	.get-started {
		padding: 32px 16px;
	}
	.contact-info {
		text-align: center;
	}
	.md-order-2 {
		order: 2;
	}
	#feedback{
		margin-top: 50px;
	}

	/* .top-footer .row .col-lg-12 {
		order: 1;
		border-top: 1px solid #4e4e4e;
		margin-top: 10px;
	} */
	.rights-reserved {
		text-align: center;
		/* margin-top: 15px; */
		font-weight: 300;
		font-size: 14px;
	}
	.btn {
		padding: 8px 18px;
	}
	.md-order-0 {
		order: 0;
	}
	.md-order-1 {
		order: 1;
	}
	.md-order-2 {
		order: 2;
	}
	.md-order-3 {
		order: 3;
	}
	.navbar-collapse {
		position: absolute;
		top: 62px;
		right: unset;
		left: -200px;
		padding-left: 15px;
		padding-right: 15px;
		padding-bottom: 15px;
		width: 200px;
		transition: all 0.4s ease;
		display: block;
	}
	.rtl .navbar-collapse {
		left: inherit;
		right: -200px;
		transition: all 0.4s ease;
	}
	.navbar-collapse.collapsing {
		height: 100vh !important;
		margin-left: 0;
		right: unset;
		transition: all 0.2s ease;
	}
	.rtl .navbar-collapse.collapsing {
		margin-right: 0;
		margin-left: inherit;
		right: inherit;
		left: unset;
		transition: all 0.4s;
	}

	.navbar-collapse.show {
		left: 0;
	}
	.rtl .navbar-collapse.show {
		right: 0;
		left: inherit;
	}
	.banner-section {
		padding-top: 80px;
		padding-bottom: 0;
		min-height: 100%;
	}
	.banner-info {
		margin-top: 50px;
		margin-bottom: 40px;
	}
	.branded-gift-img {
		margin-top: 50px;
	}
	.branded-gift-img .people-card-img {
		margin-left: 0;
		max-width: 540px;
		margin: auto;
	}
	.rtl .branded-gift-img .people-card-img {
		margin-right: 0;
	}
	.gift-card-feature {
		position: absolute;
		right: -100px;
	}
	.rtl .gift-card-feature{
		left: -100px;
	}
	.banner-section .container .row {
		justify-content: center;
	}
	.navbar-brand , .rtl .navbar-brand{
		max-width: 250px;
	}
	.personalized-title {
		width: 100%;
		height: 350px;
		margin-left: 30px;
	}
	.instant-notification-title {
		max-width: 340px;
	}
	.dashboard-performance-img {
		max-width: 450px;
	}
	#choose-gitCard .row .col-md-12 {
		order: 0;
	}
	#choose-gitCard .row .col-sm-6 {
		order: 1;
	}
	#choose-gitCard .count-number-right {
		top: 70px;
	}
	.notification-img {
		max-width: 525px;
	}
	#feedback .feedback-field .form-control {
		height: calc(2.8em + 0.75rem + -6px);
	}
	.personalized-title {
		height: inherit;
	}
	.left-text,
	.right-text {
		display: none;
	}
	.header-btn button:last-child {
		margin-left: 5px;
	}
	.PhoneInput {
		padding-left: 5px;
	}
	.pricing-plans .banner-heading {
		margin-top: 63px;
	}
	/* .signup-modal .modal-header {
		margin-bottom: 0;
		padding-bottom: 40px;
		background-color: #1b3d9a;
		z-index: unset;
	} */
	/* .signup_setup {
		top: 105px;
	} */
}

@media (max-width: 990px) {

	.additional-gif-card .notification-img {
		min-width: 520px;
	}
	.additional-gif-card {
		min-height: 480px;
	}
}

@media screen and (max-width: 894px) {
	.banner-info h2 {
		font-size: 24px;
		margin-bottom: 14px;
	}
	.banner-info h3 {
		font-size: 44px;
		line-height: 36px;
		margin-bottom: 16px;
	}
	.banner-info .banner-info-description {
		font-size: 16px;
	}
	.banner-info .btn-default {
		font-size: 16px;
		padding: 17px 16px;
	}
	.set-email {
		margin-top: 34px;
		margin-bottom: 24px;
	}
	.how-work .work-it-title p,
	.design-git-card p,
	.choose-gitCard-title p {
		font-size: 24px;
		margin-bottom: 16px;
	}
	.how-work .work-it-title h3,
	.choose-gitCard-title h3,
	.instant-title h3,
	#faq-gift-card h3{
		font-size: 30px;
		font-weight: 600;
		line-height: normal;
	}
	.comman_title label {
		font-size: 24px;
	}
	.comman_title h3,
	.gifting_digitally h3,
	.how-work .work-it-title h3 {
		font-size: 30px;
		font-weight: 600;
		line-height: normal;
		margin-bottom: 20px;
	}
	.how-work .work-it-title .btn,
	.design-git-card .work-it-title .btn,
	.design-git-card .personalized-title .btn,
	.instant-list-details button {
		font-size: 16px;
		margin-top: 4px;
		padding: 8px 18px;
	}
	.how-work-step {
		padding: 20px 16px;
	}
	.how-work-step .step-icon {
		max-width: 80px;
		height: 80px;
		min-width: 80px;
		min-height: 80px;
	}
	.how-work-step .step-icon img {
		max-width: 35px;
	}
	.how-work-step p {
		font-size: 14px;
		line-height: 18px;
	}
	.how-work .col-sm-6 {
		margin-bottom: 24px;
	}
	.design-git-card .work-it-title h3,
	.design-git-card .personalized-title h3 {
		margin-top: 30px;
		margin-bottom: 20px;
		font-size: 30px;
		font-weight: 600;
	}

	.count-number-right, .rtl .count-number-left{
		right: -25px;
	}
	.count-number-left, .rtl .count-number-right {
		left: -25px;
	}
	.section-number-left {
		width: 50px;
		height: 50px;
	}
	#choose-gitCard .container {
		padding-top: 60px;
		padding-bottom: 50px;
	}
	.instant-title h3 {
		margin-bottom: 16px;
	}
	.instant-list-details ul li {
		font-size: 16px;
		margin-bottom: 16px;
	}


	#faq-gift-card {
		padding: 24px 0;
	}

	.faq-accordion .card-header h4 {
		font-size: 16px;
		line-height: 20px;
	}
	.top-footer {
		padding: 20px 0;
	}
	.footer-link li a,
	.rights-reserved {
		font-size: 14px;
	}
}

@media (max-width: 868px) {
	.customer-card-selling h2 {
		font-size: 35px;
		margin-top: 10px;
	}
	.left-text {
		left: -36%;
		top: 90px;
	}
	.right-text {
		right: -43%;
		bottom: 70px;
	}
	.left-text p,
	.right-text p {
		font-size: 18px;
	}
	.testimonial_content .testimonial_words p {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 16px;
	}
	.testimonial_image {
		padding-right: 40px;
	}
	.rtk .testimonial_image {
		padding-left: 40px;
	}
}

@media (max-width: 867.98px) {
	.design-git-card .work-it-title h3 {
		margin-bottom: 24px;
	}
	.shadow-path {
		right: -470px;
		top: 60%;
		max-width: 920px;
	}
	.notification-img {
		max-width: 485px;
	}
	.instant-notification-title {
		min-height: 328px;
		max-width: 300px;
	}
	.instant-title h3 {
		font-size: 30px;
	}
	.dashboard-performance-img {
		max-width: 400px;
	}
	.additional-gif-card .notification-img {
		min-width: 400px;
		max-width: 450px;
	}
	.additional-gif-card {
		min-height: 400px;
	}
}

@media (max-width: 799.98px) {
	.dashboard-performance-img {
		max-width: 370px;
	}
	.signup-modal .set-email, .contact-numbers {
		margin-bottom: 26px;
	}
	.security-info .services-in p {
		font-size: 14px;
	}
	.design-git-card .personalized-title h3 {
		font-size: 28px;
	}
	
}


/* Small devices (landscape phones, less than 768px) */

@media screen and (max-width: 768px) {
	.mobileStyle{
		padding: 0 20px !important;
		margin-bottom: 20px !important;
	}
	.flex-box{margin-top: 220px;}
	.pricing-plans .banner-heading h3 {
		font-size: 20px;
	}
	.pricing-container {
		margin-top: 27px;
	}
	.banner-heading {
		margin-top: 63px;
		padding: 25px 20px;
	}
	.banner-heading h1{
		font-size: 36px;
		margin-bottom: 0px!important;
	}
	.pricing-box.premium-plans {
		transform: scale(1);
	}
	header .navbar {
		top: 5px;
	}
	.modal-logo {
		max-width: 250px;
		margin: 0;
	}
	.signup-modal .modal-header .close {
		font-size: 25px;
		width: 40px;
		height: 40px;
	}
	.company-partner-box img {
		max-width: 140px;
	}
	.loader {
		width: 4.5em;
		height: 4.5em;
		box-shadow: inset 0 0 0 0.7em;
	}
	.loader:after {
		width: 25px;
		height: 4.5em;
		border-top: 8px solid #fff;
		border-radius: 0 10.2em 10.2em 0;
		top: 0px;
		left: 25px;
		-webkit-transform-origin: 0.1em 5.1em;
		transform-origin: 0;
		-webkit-animation: load2 2s infinite ease;
		animation: load2 2s infinite ease;
		border-right: 8px solid #fff;
		border-bottom: 8px solid #fff;
	}
	.customer_stat_card {
		min-height: 200px;
	}

	.branded-gift-img .people-card-img {
		margin-left: 0;
	}
	.customer_stat_card .customer_card_info h3 {
		font-size: 40px;
		margin-bottom: 20px;
	}
	.customer_stat_card .customer_card_info p {
		min-height: 70px;
		font-size: 18px;
		padding: 0 0px;
		line-height: 23px;
	}
	.testimonial_content .testimonial_words p {
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 16px;
	}

	.testimonial_pagination .swiper-pagination-h .swiper-pagination-bullet {
		width: 14px;
		height: 14px;
	}

	.customer-card-selling small {
		font-size: 16px;
		line-height: normal;
	}
	.customer-card-selling h2 {
		font-size: 34px;
		margin-top: 16px;
		line-height: normal;
	}
	.testimonial_image {
		padding-right: 30px;
	}
	.rtl .testimonial_image {
		padding-left: 30px;
	}
	.show-m-view button, .free-trial-btn {
		max-width: 190px!important;
		font-size: 16px!important;
		padding: 14px 20px!important;
	}

	.get-started h1 {
		font-size: 32px;
		margin-bottom: 26px !important;
		line-height: normal;
	}
	.get-started {
		padding: 32px 16px;
	}
	.testimonial_image {
		padding-right: 30px;
	}
}

@media (max-width: 767.98px) {
	.navbar-nav {
		max-width: 560px;
	}
	.flex-box{margin-top: 220px;}
	.banner-info .banner-info-description {
		font-size: 19px;
	}
	.banner-info {
		text-align: center;
		max-width: 535px;
		margin: 50px auto 40px;
	}
	.testimonial_image {
		width: 100%;
		margin: auto;
		text-align: center;
		padding: 0;
	}
	.rtl .testimonial_image {
		padding: 0;
	}
	.pricing-plans .banner-heading {
		background-color: transparent;
		margin-top: 63px;
	}
	.testimonial_content {
		max-width: 449px;
		text-align: center;
		margin: 16px auto 0;
	}
	#trustedPartners{
		margin-top: 30px;
	}
	.how-work-step h4 {
		font-size: 22px;
	}
	.gift-card-feature {
		position: absolute;
		right: 0;
	}
	.rtl .gift-card-feature{
		left: 0;
	}
	.heading * {
		line-height: 30px;
		margin-bottom: 10px;
	}
	.heading h2 {
		font-size: 22px;
	}
	.heading h3 {
		font-size: 20px;
	}
	.heading div i {
		font-size: 26px;
		font-weight: 900;
	}
	.heading .free-trial span {
		font-size: 26px;
		font-weight: 900;
	}
	.get-started-title{
		text-align: center;
	}
	.footer-link li a {
		font-size: 14px;
	}
	.contact-info {
		margin-bottom: 30px;
	}
	.signup-img {
		margin: 0 auto 30px;
		display: table;
	}
	.sm-order-2 {
		order: 2;
	}
	.success-icon {
		padding: 0 !important;
	}
	.design-gift-img {
		max-width: 600px;
	}
	#feature {
		padding-top: 0;
	}

	.img-mobile-view {
		display: block !important;
	}
	.personalized-title {
		margin-left: 0;
		margin-top: 30px;
		height: 100%;
	}
	.rtl .personalized-title {
		margin-right: 0;
	}
	/* .rtl #feature .work-it-title {
		margin-left: 0px;
		text-align: left;
	} */
	.section-number-left,
	.shadow-path,
	.count-number-right,
	.count-number-left {
		display: none;
	}
	.design-git-card .work-it-title h3 {
		max-width: 100%;
		margin-top: 35px;
		margin-bottom: 24px;
	}
	.dashboard-performance-img {
		top: 50px;
		bottom: unset;
		max-width: 500px;
	}
	.instant-list-details ul li {
		max-width: 100%;
	}
	.instant-notification-title {
		min-height: 700px;
		max-width: 100%;
	}
	.instant-list-details button {
		font-size: 18px;
		padding: 12px 24px;
	}
	.performance-details,
	.dashboard-analyze {
		order: 1;
	}
	#performance-gitCard .shop-img {
		text-align: center !important;
		max-width: 450px;
		margin: auto;
	}
	#performance-gitCard .container .row {
		flex-direction: column-reverse;
	}
	.personalized-content {
		order: 1;
	}
	#choose-gitCard .container {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.faq-images {
		max-width: 400px;
		margin: auto;
		margin-bottom: 40px;
	}
	#faq-gift-card .col-md-12 {
		order: 1;
	}
	#faq-gift-card .col-md-6 {
		order: 2;
	}
	.faq-section-img {
		order: 0 !important;
	}
	.additional-gif-card .notification-img{
		display: none;
	}	
	.additional-gif-card {
		min-height: inherit;
		display: inherit;
		padding-bottom: 60px;
	}
	.get-email{
		display: block !important;
		padding: 0;
	}
	.trusted-partners {
		max-width: 70%;
		margin: auto;
	}
	.testimonial_wrapper .swiper-container{
		max-height: inherit;
	}
	.testimonial_pagination {
		top: inherit;
		left: 51%;
		transform: translate(-49%, 0);
		bottom: -30px;
	}
	.rtl .testimonial_pagination {
		right: 45%;
		left: inherit;
		transform: translate(48%, 0);
	}
	.testimonial_pagination .swiper-pagination-h .swiper-pagination-bullet {
		width: 14px;
		height: 14px;
		display: inline-block;
		margin-bottom: 0;
		margin-right: 16px;
	}
	.testimonial_pagination::after {
		position: absolute;
		content: '';
		top: 40%;
		left: -18px;
		width: calc(100% + 20px);
		height: 2px;
		background-color: #0000001a;
		z-index: -1;
	}
	.show-m-view {
		margin-top: 10px;
	}
	.dashboard-performance{
		padding-top: 0;
	}
	.instant-title, .notification-img, .instant-list-details{
		/* text-align: center; */
		margin-left: auto !important;
		margin-right: auto !important;
	}
	.notification-img, .instant-list-details ul{
		margin-bottom: 35px;
	}
	.how-work .work-it-title .btn,
	.design-git-card .work-it-title .btn,
	.design-git-card .personalized-title .btn,
	.instant-list-details button,
	#feedback button {
	    padding: 10px 18px;
		width: 100%;
		max-width: 150px;
		font-size: 15px;
	}
	.client-testimonial .client-testimonial-box{
		width: 50%;
		margin: 30px 0;
		text-align: center;
	}
	.client-testimonial {
		flex-wrap: wrap;
	}
}

@media (max-width: 667.98px) {
	.navbar .navbar-nav .nav-link {
		font-size: 17px;
	}
	.pricing-plans .banner-heading {
		background-color: transparent;
		margin-top: 58px;
	}
	.header-btn .btn {
		padding: 11px 26px;
		line-height: normal;
		font-size: 14px;
	}
	.rtl .header-btn .btn {
		font-size: 14px;
	}
	.navbar-brand, .rtl .navbar-brand, 
	.modal-logo {
		max-width: 230px;
		margin: 0;
		padding: 0;
	}
	.banner-shape .sky-shape {
		left: 5%;
		top: -290px;
	}
	.rtl .banner-shape .sky-shape {
		right: 5%;
	}
	.banner-shape .yellow-shape {
		left: 25%;
		top: -345px;
	}
	.rtl .banner-shape .yellow-shape {
		right: 25%;
	}
	#faq-gift-card h3 {
		font-size: 32px;
	}
	.navbar-collapse {
		top: 55px;
	}
}

@media screen and (max-width: 600px) {
	/* .selling-cards-number {
		flex-wrap: wrap;
		text-align: center;
		justify-content: center !important;
	} */
	.customer-card-selling small {
		font-size: 22px;
		line-height: normal;
	}
	.customer-card-selling h2 {
		font-size: 32px;
		margin-bottom: 16px;
	}
	.customer-card-selling{
		max-width: 350px;
		margin: 0 auto;
	}
	.how-work-step p {
		max-width: 260px;
	}
	.rtl .header-btn button {
		padding: 8px 13px;
	}
}

@media (max-width: 575px) {
	.container {
		padding-left: 15px;
		padding-right: 15px;
	}
	.banner-info .banner-email .form-control {
		height: calc(3.5rem);
	}
	.branded-gift-img .people-card-img {
		margin-left: 0;
		max-width: 450px;
	}
	.banner-shape .sky-shape {
		width: 580px;
		left: 16%;
		top: 0px;
		height: 340px;
		border-radius: 25px;
	}
	.rtl .banner-shape .sky-shape {
		right: 16%;
	}
	.banner-shape .yellow-shape {
		width: 580px;
		left: 39%;
		top: -60px;
		height: 340px;
		border-radius: 25px;
	}
	.rtl .banner-shape .yellow-shape{
		right: 39%;
	}
	.company-partner-box img {
		max-width: 130px;
	}

	.how-work .col-sm-6 {
		margin-bottom: 16px;
	}
	.how-work-step h4 {
		font-size: 20px;
		margin-bottom: 6px;
	}
	.how-work-step p {
		font-size: 14px;
		line-height: 16px;
		margin-bottom: 0;
		max-width: 230px;
	}
	.how-work .work-it-title .btn {
		display: none;
	}
	
	.how-work .work-step {
		border-radius: 20px;
		max-width: 350px;
		margin-left: auto;
		margin-right: auto;
	}
	.how-work-step .step-icon {
		max-width: 100px;
		height: 100px;
		min-width: 100px;
		min-height: 100px;
	}
	.how-work-step .step-icon img {
		max-width: 40px;
	}	
	.how-work-step {
		border-radius: 10px;
		padding: 30px 20px;
	}
	.show-m-view button, .free-trial-btn {
		font-size: 16px!important;
		margin-top: 5px!important;
		margin-bottom: 0!important;
		padding: 16px 24px!important;
		max-width: 185px!important;
	}

	.trusted-partners h3 {
		font-size: 16px;
	}
	.pricing-plans .banner-heading {
		margin-top: 53px;
	}
	.banner-info h2 {
		font-size: 24px;
		margin-bottom: 10px;
	}

	#faq-gift-card h3 {
		color: #241f20;
		line-height: 38px;
		font-size: 34px;
	}
	.faq-accordion .card-header h4 {
		font-size: 16px;
	}
	.faq-accordion .card-header {
		padding: 12px;
	}
	.faq-accordion .card-header button i {
		font-size: 15px;
	}
	.footer-link li {
		width: auto;
		padding: 0 10px;
	}
	.top-footer {
		padding: 20px 0;
	}
	.banner-heading {
		margin-top: 48px;
		padding: 25px 20px;
	}
	.banner-heading h1 {
		font-size: 25px;
		line-height: 43px;
		font-weight: 700;
	}
	.terms-topic>h1 {
		font-size: 24px;
	}
	.terms-topic div h2 {
		font-size: 18px;
	}
	.terms-topic div p {
		font-size: 15px;
	}
	.btn {
		padding: 9px 18px;
	}
	.set-email {
		margin-top: 30px;
		margin-bottom: 16px;
	}
	.banner-info .btn-default {
		font-size: 16px;
		font-weight: bold;
		padding: 14px 16px;
		max-width: 48%;
	}
	.banner-info .btn-default svg {
		width: 20px;
		height: 20px;
		margin-left: 4;
	}
	.rtl .banner-info .btn-default svg {
		margin-right: 4px;
	}
	.banner-info .btn-default:last-child svg {
		width: 13px;
		height: 13px;
	}
	.header-btn .btn , .rtl .header-btn .btn{
		padding: 9px 20px;
		font-size: 13px;
	}
	.navbar-brand, .rtl .navbar-brand {
		max-width: 175px;
	}
	.how-work .work-it-title h3,
	.choose-gitCard-title h3,
	.gifting_digitally h3,
	#faq-gift-card h3 {
		font-size: 26px;
		margin-bottom: 20px;
	}
	.comman_title {
		margin-bottom: 48px;
	}
	.comman_title h3 {
		font-size: 28px;
	}
	.how-work .work-it-title p,
	.design-git-card p,
	.choose-gitCard-title p,
	.comman_title label {
		font-size: 20px;
		margin-bottom: 10px;
		align-items: center;
	}

	.dashboard-performance-img {
		top: 30px;
		max-width: 450px;
	}
	.instant-notification-title {
		min-height: 640px;
		max-width: 100%;
	}
	#performance-gitCard .shop-img {
		text-align: center !important;
		max-width: 370px;
		margin: auto;
	}
	#choose-gitCard .row .col-sm-6 {
		margin-bottom: 18px;
	}

	#feedback .feedback-field label,
	#feedback .text-message label {
		font-size: 17px;
	}
	#feedback .feedback-field .form-control {
		font-size: 16px;
		padding: 14px 16px;
		height: calc(2.5em + 0.75rem + 0px);
	}
	.feedback-field {
		margin-bottom: 16px;
	}
	.faq-images {
		max-width: 300px;
		margin-bottom: 40px;
	}
	.signup-modal .modal-header {
		padding: 20px 20px 20px;
		justify-content: center;
	}
	.modal-logo {
		max-width: 220px;
		margin: 0;
	}
	.signup-modal .modal-header .close {
		font-size: 24px;
		width: 35px;
		height: 35px;
		position: absolute;
		top: 12px;
		right: 12px;
	}
	.signup_setup {
		top: 50px;
	}
	.navbar-collapse {
		top: 48px;
	}
	.testimonial_image {
		max-width: 135px;
	}
	.trusted-partners {
		max-width: 90%;
		padding: 26px 16px 20px;
	}
	.get-started h1 {
		font-size: 28px;
		margin-bottom: 20px !important;
	}
	.gifting_digitally {
		margin-top: 50px;
		padding: 30px 0;
		background: #F4F8FF;
	}
	.client-testimonial .client-testimonial-box{
		width: 100%;
		margin: 30px 0;
	}
}

@media (max-width: 515px) {
	 .rtl .header-btn .btn{
		 font-size: 10px;
	 }
	 .rtl .setup-account-heading p{
		min-height: 50px;
	}
}
@media (max-width: 487px){
	.signup-modal .modal-header {
		padding: 16px 16px 30px;
	}
	.signUp-box {
		margin: 0;
		max-width: 100%;
	}
	.signup_setup {
		position: absolute;
		top: 60px;
		left: 0;
		right: 0;
		width: 100%;
		height: auto;
		min-height: auto;
		max-height: unset;
		background-repeat: inherit;
		background-position: inherit;
		transition: all 0.2s ease;
		background-image: inherit;
	}
	.signup_setup img{
		width: 100%;
	}
	.setup-account-heading p {
		font-size: 100%;
		line-height: 20px;
	}
	.setup-account-heading h3 {
		font-size: 125%;
	}
	.stepAccount h4 {
		font-size: 20px;
		font-weight: 500;
	}
	.new-account-box {
		border-radius: 20px;
	}
	.login-modal{
		top: 60px !important;
	}
	.flex-box{
		margin-top: 43%;
		width: calc(100% - 20%);
		max-width: 390px;
	}
}

@media (max-width: 480px) {
	body {
		font-size: 14px;
	}
	.banner-info .banner-email .form-control {
		height: calc(3.1rem);
		font-size: 15px;
	}
	.header-btn .btn , .rtl .header-btn .btn {
		padding: 7px 13px;
		margin: 0 2px;
		font-size: 12px;
	}
	.navbar-toggler {
		font-size: 16px;
	}
	.banner-info .btn-default {
		font-size: 15px;
		padding: 11px 4px;
	}
	.branded-gift-img .people-card-img {
		max-width: 400px;
	}
	.how-work-step p {
		max-width: 220px;
	}
	.signup-modal .set-email.site-name-preview button{
		font-size: 14px;	
	}
	.heading * {
		line-height: 26px;
	}
	.heading h2 {
		font-size: 20px;
	}
	.heading h3 {
		font-size: 18px;
	}
	.banner-info h3 {
		font-size: 40px;
		line-height: normal;
		margin-bottom: 10px;
	}
	.branded-gift-img {
		margin-top: 20px;
	}
	.banner-info {
		margin-top: 35px;
	}
	.banner-info .banner-info-description {
		font-size: 16px;
		line-height: normal;
		color: #181717;
		font-weight: 400;
	}
	.gift-card-feature {
		right: 10px;
	}
	.rtl .gift-card-feature{
		left: 10px;
	}
	.gift-card-feature-list {
		padding: 11px 12px;
		margin-bottom: 8px;
	}
	.gift-card-feature-list p {
		font-size: 11px;
		line-height: 8px;
	}
	.gift-card-feature-list p svg {
		font-size: 17px;
		margin-right: 2px;
		display: none;
	}
	.rtl .gift-card-feature-list p svg{
		margin-left: 2px;
	}
	.footer-link li {
		padding: 3px 10px;
		margin: 0;
	}
	.set-email button {
		min-width: 97px;
		font-size: 13px;
		padding: 2px 10px;
	}
	.contact-heading {
		padding: 20px 0;
		margin-top: 92px;
	}
	.contact-heading h1 {
		font-size: 36px;
		line-height: 50px;
	}
	.success-icon {
		font-size: 4rem;
		padding: 0 !important;
	}
	.success-modal h2 {
		margin: 8px 0 8px !important;
		font-size: 24px;
	}
	.success-modal .text-center {
		padding: 0 1px 0px !important;
	}
	.success-note {
		padding: 0 !important;
		line-height: 17px;
	}
	.success-modal .modal-logo {
		max-width: 150px;
	}
	.success-modal .modal-header {
		padding: 8px 10px;
	}
	#faq-gift-card h3 {
		line-height: 30px;
		font-size: 26px;
	}
	.login-modal {
		top: -100px;
	}
	.login-container {
		min-height: inherit !important;
	}
	.PhoneInput {
		padding-right: 55px;
	}
	.terms-contract-sec p {
		padding-top: 0;
	}
	.navbar .navbar-nav .nav-link {
		font-size: 16px;
	}

	.design-git-card .work-it-title h3,
	.design-git-card .personalized-title h3,
	.instant-title h3 {
		font-size: 28px;
		/* margin-top: 30px; */
		margin-bottom: 20px;
	}
	.dashboard-performance-img {
		max-width: 95%;
	}
	.client-testimonial .client-testimonial-box img {
		max-width: 50%;
	}

	/* .shape_one {
    	background-image: url(../images/mobile-shape-setup1.png) !important;
  	}
 	 .shape_two {
    	background-image: url(../images/mobile-shape-setup2.png) !important;
  	} */
	/* .signup-modal .modal-header .close::after {
		position: absolute;
		content: "";
		bottom: -23px;
		width: 25px;
		right: -17px;
		height: 25px;
		border-radius: 50px;
		background-color: #FFDD1C;
	}
	.rtl .signup-modal .modal-header .close::after{
		left: -17px;
		right: inherit;
	} */
	.signup_setup {
		top: 48px;
	}
	.setup-account-title {
		top: -65px;
	}
	.rtl .header-btn .btn{
		font-size: 10px;
	}
	.rtl .banner-info .btn-default {
		padding-top: 10px;
		padding-bottom: 10px;
		font-size: 13px;
	}
	.pricing-plans .banner-heading {
		margin-top: 48px;
	}
}

@media (max-width: 479px) {
	.set-email .custom-close, .contact-numbers .custom-close {
		top: 13px;
	}
	.custom-close svg {
		font-size: 26px;
	}
	.input_password .custom-close {
		top: 11px;
	} 
}

@media (max-width: 460px) {
	.signup_setup {
		top: 60px;
	}
}
@media (min-width: 426px) and (max-width: 450px) {
    .rtl .middle_step {
		top: 85px;
	}
}

@media (max-width: 443px) {
	.header-btn .btn , .rtl .header-btn .btn {
		padding: 7px 12px;
		margin: 0 2px;
		font-size: 12px;
	}
	.signup_setup {
		top: 65px;
	}
	.flex-box{
		width: calc(100% - 16%);
	}
}


/* X-Small devices (portrait phones, less than 576px) */

@media (max-width: 425px) {
	.how-work .work-it-title .btn,
	.design-git-card .work-it-title .btn,
	.design-git-card .personalized-title .btn {
		padding: 12px 24px;
		width: 100%;
		font-size: 14px !important;
	}
	.show-m-view button, .free-trial-btn {
		width: 100%;
		font-size: 14px!important;
		padding: 15px 20px!important;
		max-width: 170px!important;
	}
	.get-started button {
		padding: 14px 12px;
	}
	.get-started button a{
		color: #fff !important;
	}
	.design-git-card .work-it-title h3 {
		font-size: 24px;
	}
	.navbar-collapse {
		top: 45px;
	}
	.header-btn .btn , .rtl .header-btn .btn{
		padding: 7px 12px;
		font-size: 12px;
	}
	.faqtext {
		font-size: 20px;
	}
	.navbar-brand, .rtl .navbar-brand,
	.modal-logo {
		max-width: 155px;
	}
	.get-started h1 {
		font-size: 24px;
		margin-bottom: 20px !important;
	}
	.banner-shape .sky-shape {
		width: 400px;
		left: 30%;
		top: 0px;
		height: 300px;
		border-radius: 20px;
	}
	.rtl .banner-shape .sky-shape {
		right: 30%;
	}
	.banner-shape .yellow-shape {
		width: 400px;
		left: 44%;
		top: -15px;
		height: 300px;
		border-radius: 20px;
	}
	.rtl .banner-shape .yellow-shape {
		right: 44%;
	}
	.branded-gift-img .people-card-img {
		max-width: 360px;
	}

	.how-work .work-it-title h3,
	.choose-gitCard-title h3,
	.gifting_digitally h3,
	#faq-gift-card h3 {
		font-size: 24px;
		margin-bottom: 20px;
	}
	.design-git-card .personalized-title h3 {
		font-size: 24px;
	}

	.comman_title h3 {
		font-size: 24px;
	}
	.how-work .work-it-title p,
	.design-git-card p,
	.choose-gitCard-title p,
	.comman_title label {
		font-size: 16px;
		margin-bottom: 10px;
		align-items: center;
	}
	.design-gift-img {
		max-width: 320px;
		margin: auto;
	}
	.instant-title h3 {
		font-size: 24px;
		margin-bottom: 20px;
	}
	.instant-list-details ul li {
		font-size: 14px;
		margin-bottom: 14px;
	}
	.how-work .work-it-title .btn,
	.design-git-card .work-it-title .btn,
	.design-git-card .personalized-title .btn,
	.instant-list-details button,
	#feedback button {
	    padding: 10px 18px;
		width: 100%;
		max-width: 150px;
		font-size: 15px;
	}
	.setup-account-heading p{
		min-height: 40px;
	}
	.instant-notification-title {
		min-height: 606px;
	}
	#performance-gitCard .shop-img {
		max-width: 300px;
	}
	#choose-gitCard .card .select_btn {
		padding: 8px 8px !important;
		max-width: 50%;
	}
	#choose-gitCard .card .select_btn button {
		font-size: 14px;
	}

	#feedback .feedback-field .form-control {
		padding: 10px 16px;
		height: calc(2em + 0.75rem + 0px);
		font-size: 15px;
	}
	.feedback-field .custom-close {
		top: 39px !important;
	}
	#feedback .feedback-field label,
	#feedback .text-message label {
		font-size: 16px;
	}
	#feedback .text-message textarea {
		font-size: 14px;
		padding: 16px 20px;
	}
	.faq-accordion .card-header h4 {
		font-size: 13px;
		line-height: 15px;
	}
	.faq-accordion .card-header {
		padding: 8px;
	}
	.faq-accordion .accordion-box .card-body {
		font-size: 14px;
		padding: 10px 0px;
		margin-left: 16px;
		margin-right: 16px;
	}

	.faq-images {
		max-width: 275px;
		margin-bottom: 30px;
	}
	.faq-accordion .accordion-box {
		border-radius: 6px !important;
	}
	#faq-gift-card {
		padding: 40px 0 20px;
		margin-bottom: 12px;
	}
	.custom-close svg {
		font-size: 24px;
	}
	.modal-logo {
		max-width: 200px;
		margin: 0;
	}
	.setup-account-heading{
		margin-top: 12px;
	}
	.setup-account-title {
		top: -67px;
	}
	/* .shape_one {
		background-image: url(../images/mobile-shape-setup1.svg);
	}
	.shape_two {
		background-image: url(../images/mobile-shape-setup2.svg);
	} */
	/* .account-details{
		max-width: 290px;
		margin: 0 auto;
	} */
	.new-account-box {
		margin: 0 auto;
		padding: 16px 16px;
	}
	.actionBtn>button {
		font-size: 14px;
		margin-bottom: 8px;
		padding: 8px 6px;
	}
	.signup_setup {
		top: 100px;
	}
	.name-preview input{
		max-width: 100px;
		padding: 3px 6px;
		margin-right: 2px;
	}
	.flex-box {
		margin: 43% auto 0;
	}
	.contact-number .country_box{
		margin-right: 10px;
		min-width: 100px;
	}
	.partners-slider .swiper-container {
		margin-top: 20px;
	}
	.contact-number .country_box .MuiSelect-outlined{
		padding-right: 28px !important;
	}
	.rtl .contact-number .country_box{
		margin-left: 10px;
		margin-right: 0;
		min-width: 100px;
	}
	.rtl .contact-number .country_box .MuiSelect-outlined{
		padding-left: 28px !important;
		padding-right: 14px !important;
	}
	.rtl .header-btn .btn{
		font-size: 9px;
	}
}

@media screen and (max-width: 420px) {
	#feedback button {
	    padding: 12px 24px;
		max-width: 165px;
	}
	.banner-shape .sky-shape {
		width: 400px;
		left: 23%;
		top: 0px;
		height: 260px;
	}
	.rtl .banner-shape .sky-shape {
		right: 23%;
	}
	.banner-shape .yellow-shape {
		width: 400px;
		left: 40%;
		top: -30px;
		height: 260px;
	}
	.rtl .banner-shape .yellow-shape {
		right: 40%;
	}
	.modal-open .tooltip {
		width: 160px !important;
	}
	.terms-topic>h1 {
		font-size: 20px;
	}
	.banner-heading h1 {
		font-size: 30px;
		line-height: 35px;
	}
	.branded-gift-img .people-card-img {
		max-width: 290px;
	}
	.trusted-partners h3 {
		font-size: 15px;
	}
	.partners-slider .swiper-container{
		margin-top: 12px;
    	margin-bottom: 0;
	}
	.testimonial_content .testimonial_words span {
		height: 4px;
	}
	.testimonial_content .testimonial_info label {
		font-size: 14px;
	}
	.testimonial_pagination .swiper-pagination-h .swiper-pagination-bullet {
		margin-right: 12px;
	}
	.testimonial_pagination .swiper-pagination-h .swiper-pagination-bullet {
		width: 14px;
		height: 14px;
	}
	.how-work-step p {
		max-width: 170px;
	}
	.testimonial_image {
		max-width: 125px;
	}
	.how-work .work-it-title .btn,
	.design-git-card .work-it-title .btn,
	.design-git-card .personalized-title .btn,
	.instant-list-details button,
	#feedback button {
	    padding: 8px 15px;
		width: 100%;
		max-width: 130px;
		font-size: 14px;
	}
	.pricing-plans .banner-heading {
		margin-top: 43px;
	}
	.banner-heading h1 {
		font-size: 21px;
		line-height: 35px;
	}
	.pricing-plans .banner-heading h3 {
		font-size: 18px;
	}
}

@media screen and (max-width: 410px){
	.rtl .header-btn .btn {
		padding: 5px 6px;
		font-size: 8px;
	}
	.navbar-toggler {
		margin-right: 5px;
		margin-left: 5px;
	}
	.navbar-brand, .rtl .navbar-brand, .modal-logo {
		max-width: 160px;
	}
	.how-work .work-it-title .btn,
	.design-git-card .work-it-title .btn,
	.design-git-card .personalized-title .btn,
	.instant-list-details button,
	#feedback button {
	    padding: 8px 15px;
		width: 100%;
		max-width: 130px;
		font-size: 14px;
	}
	.signup_setup {
		top: 110px;
	}
}

@media screen and (max-width: 390px){
	.signup_setup {
		top: 120px;
	}
}

@media (max-width: 376px) {
	.banner-info .btn-default {
		padding: 11px 2px;
		font-size: 14px;
	}
	.gifting_digitally h3 {
		font-size: 24px;
		margin-bottom: 20px;
	}
	.banner-shape .sky-shape {
		width: 400px;
		left: 21%;
		top: 0px;
		height: 260px;
	}
	.rtl .banner-shape .sky-shape {
		right: 21%;
	}
	.banner-shape .yellow-shape {
		width: 400px;
		left: 40%;
		top: -30px;
		height: 260px;
	}
	.rtl .banner-shape .yellow-shape {
		right: 40%;
	}
	.how-work .work-it-title .btn,
	.design-git-card .work-it-title .btn,
	.design-git-card .personalized-title .btn,
	.instant-list-details button,
	#feedback button {
	    padding: 8px 15px;
		width: 100%;
		max-width: 130px;
		font-size: 14px;
	}
}


/* X-Small devices (portrait phones, less than 576px) */

@media (max-width: 375px) {
	#performance-gitCard .shop-img {
		max-width: 265px;
	}
	.header-btn .btn, .rtl .header-btn .btn {
		padding: 8px 7px;
		font-size: 12px;
	}
	.navbar-brand , .rtl .navbar-brand{
		max-width: 148px;
	}
	.modal-logo {
		max-width: 190px;
	}
	.header-btn .btn{
		padding: 7px 10px;
		font-size: 11px;
	}
	.rtl .header-btn .btn {
		font-size: 8px;
		padding: 7px 10px;
	}
	.testimonial_content .testimonial_words p {
		font-size: 12px;
		line-height: 16px;
		margin-bottom: 10px;
	}
	.how-work-step h4 {
		font-size: 18px;
		margin-bottom: 6px;
	}
	.signup_setup {
		top: 130px;
	}
}

@media (max-width: 374px) {
	.navbar-collapse {
		top: 39px;
	}
	.header-btn .btn , .rtl .header-btn .btn{
		padding: 6px 10px;
		margin: 0 2px;
		font-size: 12px;
	}
	.navbar-brand, .rtl .navbar-brand {
		max-width: 140px;
	}
	.signup-modal .navbar-brand {
		max-width: 40px;
	}
	.navbar-toggler-icon {
		width: 18px;
	}
	.header-btn .btn {
		font-size: 10px;
	}
	.rtl .header-btn .btn {
		font-size: 8px;
		padding: 4px 6px;
	}
	/* .signup_setup {
		top: 100px;
	} */
	.setup-account-heading p {
		font-size: 98.8%;
	}
}

@media screen and (max-width: 360px) {
	.banner-shape .sky-shape {
		width: 350px;
		left: 20%;
		top: 0px;
		height: 250px;
	}
	.banner-shape .yellow-shape {
		width: 350px;
		left: 40%;
		top: -30px;
		height: 250px;
	}
	.rtl .banner-shape .yellow-shape {
		right: 40%;
	}
	.rtl .setup-account-heading p {
		min-height: 40px;
	}
	.banner-info h2 {
		font-size: 20px;
		line-height: normal;
	}
	.banner-info h3 {
		font-size: 40px;
		line-height: normal;
		margin-bottom: 10px;
	}
	.banner-info .banner-info-description {
		font-size: 14px;
		line-height: normal;
		color: #181717;
		font-weight: 400;
	}
	.set-email {
		margin-top: 26px;
	}
	.gift-card-feature-list p {
		font-size: 10px;
		line-height: 10px;
	}
	.gift-card-feature-list p svg {
		font-size: 15px;
		margin-right: 2px;
	}
	.gift-card-feature-list {
		padding: 8px 6px;
		margin-bottom: 6px;
	}
	.modal-dialog.success-modal {
		margin: 1.75rem 7%;
	}
	#faq-gift-card h3 {
		line-height: 26px;
		font-size: 22px;
	}
	.navbar-brand, .rtl .navbar-brand {
		max-width: 140px;
	}
	.modal-logo {
		max-width: 165px;
	}
	.how-work .work-it-title {
		margin-bottom: 40px;
	}
	.signup_setup {
		top: 136px;
	}
	.site_name_preview{
		font-size:14px;
	}
	.name-preview input {
		max-width: 80px;
	}
}


/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 340px) {
	.signup_setup {
		top: 145px;
	}
	.signup-modal .set-email.site-name-preview button{
		font-size: 14px;	
	}
}
@media (max-width: 321px) {
	.signup_setup {
		top: 152px;
	}
}

@media (min-width: 290px) and (max-width: 320px) {
	.navbar-brand , .rtl .navbar-brand{
		max-width: 115px;
	}
	.header-btn .btn {
		padding: 6px 10px;
		font-size: 10px;
	}
	.rtl .header-btn .btn{
		font-size: 8px;
	}
	.navbar-brand, .rtl .navbar-brand {
		max-width: 130px;
	}
}

@media (min-width: 1300px) {
	.container {
		max-width: 1295px;
	}
	.testimonal_section>.container {
		max-width: 1149px;
	}
}

@media (min-width: 576px) {
	.modal-dialog.success-modal {
		max-width: 500px;
		margin: 1.75rem auto;
	}
}

@media (min-width: 320px) {
	.modal-dialog.success-modal {
		margin: 1.75rem 10%;
	}
}