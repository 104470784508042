@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;600;700;800&family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("./ionicons.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.5.0/css/all.css");
@font-face {
    font-family: 'DIN Next LT Arabic Bold';
    src: url('../fonts/DIN\ Next\ LT\ Arabic\ Light.ttf') format('truetype');
    font-weight: lighter;
    font-style: normal;
}
@font-face {
    font-family: 'DIN Next LT Arabic Bold';
    src: url('../fonts/DIN\ Next\ LT\ Arabic\ Regular.ttf') format('truetype');
    font-weight: regular;
    font-style: normal;
}
@font-face {
    font-family: 'DIN Next LT Arabic Bold';
    src: url('../fonts/DIN\ Next\ LT\ Arabic\ Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
   
html {
	scroll-behavior: smooth;
}

iframe{
	display: none;
}

body {
	font-size: 16px;
	line-height: 20px;
	color: #939393;
	overflow-x: hidden;
	direction: ltr;
	text-align: left;
	font-family: "Roboto", sans-serif; 
}
.rtl, [dir=rtl] {
  text-align: right;
  direction: rtl;
  font-family: "DIN Next LT Arabic Regular";
  
}
.not-rtl, [dir=rtl] {
  text-align: left;
  direction: ltr;
  font-family: "DIN Next LT Arabic Regular";
}
body::-webkit-scrollbar {
	width: 0; 
}
.text-color-white{
	color: #fff !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Roboto", sans-serif;
}

p {
	color: #939393;
	line-height: 26px;
}

img {
	max-width: 100%;
}

ul {
	margin: 0;
}

.justify-content-evenly{
	justify-content: space-evenly;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.input-error-msg {
	color: #dc3545 !important;
	font-size: 14px !important;
	font-weight: normal;
	line-height: normal;
	margin-left: 4px !important;
	margin-top: 4px !important;
	min-height: 20px;
	margin-bottom: 0;
}

.site-name-msg {
	color: #1B3D9A !important;
	font-size: 14px !important;
	text-align: left;
	font-weight: normal;
	line-height: normal;
	margin-left: 4px !important;
	margin-top: 4px !important;
	min-height: 20px;
	margin-bottom: 0;
}


/* Firefox */

input[type="number"] {
	-moz-appearance: textfield;
}

.btn {
	padding: 10px 28px;
}
.btn:hover{color: #ffffff}
.pricing-box .btn:hover{color: #1B3D9A}
.btn img {
	max-width: 20px;
	margin-right: 15px;
}

.btn:focus {
	box-shadow: none;
	outline: none;
}

.btn-default {
	background-color: #1B3D9A;
	color: #fff;
	font-weight: 500;
	border-radius: 46px;
	cursor: pointer;
	transition: all 0.3s;
	border: none;
}

.btn[disabled] {
	pointer-events: none;
}

.btn-default.disabled {
	cursor: auto;
}

.btn-outline {
	color: #1B3D9A;
	background-color: #ffffff;
	font-weight: 500;
	border-radius: 46px;
	transition: all 0.3s;
	border: 2px solid #1B3D9A;
}

.btn i {
	font-size: 30px;
	vertical-align: middle;
	line-height: 0;
}

.btn i:before {
	line-height: 0;
}

.text-blue {
	color: #1B3D9A !important;
}
.text-black{
	color: black !important;
}
.font_size{
	font-size: 1.5rem;
}
.text-yellow {
	color: #FFDD1C !important;
}
.bg-yellow {
	background-color: #ffee63;
}

.bg-blue {
	background-color: #1B3D9A;
}

.bg-liteWhite {
	background-color: #f4f8ff;
}


input:focus {
	box-shadow: none;
}

/* .section {
	padding: 100px 0;
} */



.heading * {
	font-weight: 700;
	line-height: 40px;
	color: #4d533c;
	margin-bottom: 20px;
}

.heading h2 {
	font-weight: 900;
	font-size: 48px;
}

.heading h3 {
	font-weight: 600;
	font-size: 32px;
}

.cursor-pointer {
	cursor: pointer;
}


/*domain success classes*/

.form-control.success_domain {
	border: 1.5px solid #1c8df0;
	color: #1c8df0;
}

.success_domain:focus {
	box-shadow: none !important;
	outline: 0 none !important;
}


/*Loader Css*/

.loader-wrap {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 999;
}

.loader-wrap:before {
	content: "";
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #fff;
	opacity: 0.5;
}

.loader,
.loader:before,
.loader:after {
	border-radius: 50%;
}

.loader {
	color: #ffe256;
	font-size: 11px;
	text-indent: -99999em;
	margin: 55px auto;
	position: relative;
	width: 10em;
	height: 10em;
	box-shadow: inset 0 0 0 1em;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}

.loader:before,
.loader:after {
	position: absolute;
	content: "";
}

.loader:before {
	width: 4.9em;
	height: 10.2em;
	border-radius: 10.2em 0 0 10.2em;
	top: -0.1em;
	left: -0.1em;
	-webkit-transform-origin: 5.1em 5.1em;
	transform-origin: 5.1em 5.1em;
	-webkit-animation: load2 2s infinite ease 1.5s;
	animation: load2 2s infinite ease 1.5s;
}

.loader:after {
	width: 5.2em;
	height: 10.2em;
	border-top: 12px solid #fff;
	border-radius: 0 10.2em 10.2em 0;
	top: -0.1em;
	left: 4.9em;
	-webkit-transform-origin: 0.1em 5.1em;
	transform-origin: 0.1em 5.1em;
	-webkit-animation: load2 2s infinite ease;
	animation: load2 2s infinite ease;
	border-right: 12px solid #fff;
	border-bottom: 12px solid #fff;
}

@-webkit-keyframes load2 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes load2 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

/* Header Css */
.modify_header header .navbar {
	top: 0;
	background-color: white;
}
.modify_header header .custom-select-dropdown select{
	font-weight: 400;
	color: #000;
}
header .navbar {
	/* background-color: #fff; */
	padding-left: 0 !important;
	padding-right: 0 !important;
	top: 32px;
	/* transition: all 0.01s ease; */
}

.sticky .navbar {
	top: 0;
	background-color: #fff;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
	/* transition: all 0.01s ease; */
}

.custom-loader {
	display: inline-block;
	width: 1.2rem !important;
	height: 1.2rem !important;
	vertical-align: -0.125em;
	border: 0.2em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
}

.navbar-brand {
	max-width: 300px;
}

.navbar .navbar-nav .nav-link {
	font-size: 18px;
	line-height: normal;
	color: #000;
	padding: 10px 16px;
	cursor: pointer;
	margin: 0 5px;
	font-weight: 400;
}

.navbar .navbar-nav .nav-link:hover {
	color: #241f20;
}

.navbar .navbar-nav .nav-link.active {
	color: #241f20;
}

.z-index-11 {
	z-index: 11 !important;
}

.header-btn button {
    transition: all 0.3s;
    transition-duration: 0.3s;
   	padding: 10px 26px;
    border-radius: 46px;
	color: #fff;
	background-color: transparent;
	border: 2px solid #fff;
    box-shadow: 0 30px 30px rgb(0 0 0 / 6%);
    font-weight: bold;
    font-size: 16px;
}
.sticky .navbar .header-btn button{
	color: #1B3D9A;
	border: 2px solid #1B3D9A;
}
.header-btn button + button{
	margin-left: 10px;
	color: #1B3D9A;
	border: 2px solid #FFDD1C !important;
    background-color: #FFDD1C !important;
}
.header-btn button + button:hover{
	color: #1B3D9A;
}
.language-select {
    align-items: center;
    display: flex;
    margin-right: 9px;
}
.rtl .language-select {
    margin-left: 9px;
}
.custom-select-dropdown {
    color: #424242;
    position: relative;
}
.custom-select-dropdown select {
	-webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 0;
    padding-right: 12px;
    color: #c3c2c2;
    font-weight: 600;
}
.sticky .custom-select-dropdown select {
	color: #000;
	font-weight: 400;
}
.custom-select-dropdown:after {
    border-bottom: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #607d8b;
    content: "";
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 8px;
}
.custom-select-dropdown select:focus {
    box-shadow: none;
    outline: none;
}

/* Banner Css */

.banner-section {
	/* min-height: calc(100vh + 50px); */
	padding: 200px 0 0;
	display: flex;
	align-items: center;
	overflow: hidden;
	position: relative;
	background-repeat: no-repeat;
}

.banner-info {
	max-width: 525px;
}

.banner-info h2 {
	font-size: 32px;
    font-weight: 400;
    color: #241f20;
    line-height: normal;
    margin-bottom: 16px;
}

.banner-info h3 {
	font-size: 52px;
    font-weight: 700;
    display: block;
	color: #1B3D9A;
    line-height: 46px;
    margin-bottom: 34px;
}

.banner-info .banner-info-description {
	font-size: 20px;
    line-height: 29px;
    color: #181717;
    font-weight: 400;
}

.set-email {
	margin-top: 48px;
	margin-bottom: 28px;
	position: relative;
}
.banner-info .banner-email .form-control {
    height: calc(4rem);
    border-radius: 49px;
    padding-left: 28px;
}
.banner-email .custom-close {
    top: 20px!important;
    right: 25px;
}
.banner-info .set-email p{
	margin-left: 30px !important;
}
.set-email .custom-close, .contact-numbers .custom-close{
	top: 17px;
    right: 25px;
}

.feedback-field .custom-close {
	top: 36px !important;
}

/* .set-email input::placeholder {
  color: #626262 !important;
}

.set-email input:-ms-input-placeholder {
  color: #626262;
}

.set-email input::-ms-input-placeholder {
  color: #626262;
} */

/* .set-email input {
  width: 100%;
  padding: 10px 20px;
  height: 64px;
  font-size: 16px;
  font-weight: normal;
  border-radius: 8px;
  margin-bottom: 6px;
  border: 2px solid #1B3D9A;
  color: #000000 !important;
}

.set-email input:focus {
  box-shadow: 0 0 0 0.2rem rgb(49 142 208 / 25%) !important;
} */

.banner-info .btn-default {
	font-size: 18px;
	font-weight: bold;
	padding: 17px 20px;
	max-width: 47%;
	width: 100%;
}

.banner-info a {
	max-width: 47%;
	width: 100%;
}

.banner-info a .btn-default {
	max-width: unset;
}

.banner-info .btn-default svg {
	width: 30px;
	height: 30px;
	margin-left: 10px;
}

.banner-info .btn-default:last-child, .subscribe-info button {
	margin-right: 0px;
	height: 100%;
	background-color: #ffffff;
	color: #1B3D9A;
	border: 2px solid #1B3D9A;
}
.subscribe-info button:hover{
    transform: translateY(0px);
	color: #1B3D9A;
}

.banner-info .btn-default:last-child svg {
	width: 17px;
	height: 17px;
}

input[type="email"].big-dog::-webkit-input-placeholder {
	color: orange;
}

.set-email p, .contact-numbers  p {
	font-size: 14px;
	font-weight: normal;
	margin-left: 20px;
	margin-bottom: 0;
	white-space: nowrap;
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
}

.set-email p:empty , .contact-numbers  p:empty {
	display: none;
}

.set-email .input-error-msg, .contact-number .input-error-msg {
	white-space: normal;
	overflow: visible;
}

.set-email button {
	min-width: 194px;
	color: #fff;
	font-weight: 500;
	font-size: 20px;
}

.gift-card-feature {
	position: absolute;
	right: 0;
}
.rtl .gift-card-feature{
	left: 0;
	right: inherit;
}

.branded-gift-img {
	position: relative;
}

.gift-card-feature-list {
	padding: 10px 20px;
	background: #ffffff;
	box-shadow: 0px 3px 3px #0000000d;
	border-radius: 23px;
	margin-bottom: 16px;
	width: fit-content;
	cursor: pointer;
}
.rtl .gift-card-feature-list{
	margin-left: auto;
}

.gift-card-feature-list p {
	line-height: 18px;
	font-size: 15px;
	text-transform: capitalize;
	font-weight: normal;
	letter-spacing: 0px;
	color: #898989;
	margin-bottom: 0;
}

.animation-once {
	animation-fill-mode: forwards;
}

.gift-card-feature>div:nath-child(2) {
	animation-delay: 2000ms;
}

.gift-card-feature>div:nath-child(3) {
	animation-delay: 2000ms;
}

.gift-card-feature>div:nath-child(4) {
	animation-delay: 2000ms;
}

.gift-card-feature-list p svg {
	font-size: 23px;
	margin-right: 8px;
}
.rtl .gift-card-feature-list p svg{
	margin-left: 8px;
	margin-right: inherit;
}

.branded-gift-img .people-card-img {
	margin-left: -70px;
	z-index: 3;
	max-width: 667px;	
}
/* .branded-gift-img .people-card-img img{
	max-width: 500px;
} */

.banner-shape span {
	position: absolute;
	border-radius: 100%;
	display: block;
	z-index: 1;
	pointer-events: none;
}

.banner-shape .sky-shape {
	width: 1350px;
	left: 48%;
	top: -245px;
	height: 520px;
	z-index: -1;
	background-color: #1B3D9A;
	border-radius: 50px;
	transform: rotate(132deg);
}
.rtl .banner-shape .sky-shape {
    right: 48%;
	left: inherit;
    transform: rotate(225deg);
}

.banner-shape .yellow-shape {
    width: 1350px;
    left: 62%;
    top: -347px;
    height: 520px;
    z-index: -2;
    background-color: #FFDD1C;
    border-radius: 50px;
    transform: rotate(132deg);
}
.rtl .banner-shape .yellow-shape {
    right: 62%;
	left: inherit;
    transform: rotate(225deg);
}

#faq-gift-card {
	padding: 100px 0;
	overflow: hidden;
}

.faq-accordion .card-header {
	background-color: transparent;
	margin-bottom: 2px !important;
	border-color: #909ca3;
	display: flex;
	justify-content: space-between;
}

.faq-accordion .accordion-box {
	background-color: #ffffff;
	box-shadow: 0px 3px 6px #0000000d;
	border: 1px solid #eaeaea;
	border-radius: 10px !important;
	margin-bottom: 16px;
}

.faq-accordion .accordion-box .card-header {
	border: none;
}

.faq-accordion .accordion-box .card-header button {
	margin: 0px 8px;
}

.faq-accordion .accordion-box .card-body {
	border-top: 1px solid #e0e0e0;
	color: #707070;
	font-size: 15px;
	line-height: 20px;
	padding-left: 0;
	padding-right: 0;
	margin-left: 20px;
	margin-right: 20px;
}

.faq-accordion .card-header h4 {
	font-size: 18px;
	font-weight: 500;
	color: #241f20;
	margin: auto 0;
	line-height: 21px;
}

.faq-accordion .card-header button {
	padding: 0;
}

.faq-accordion .card-header button i {
	font-size: 16px;
	color: #241f20;
}


/* Get Started Css */
.get-started{
	/* max-width: 1066px; */
    margin: auto;
	padding: 55px 16px;
	border-radius: 10px;
}
.get-started-title{
	/* max-width: 447px; */
	margin: 0 auto;
}
.get-started h1{
	color: #fff;
	font-size: 36px;
	line-height: 48px;
	font-weight: 500;
}
.get-started button{
	color: #fff;
	font-size: 16px;
    font-weight: bold;
    padding: 15px 12px;
    max-width: 240px;
    width: 100%;
	border: 2px solid #fff;
	border-radius: 50px;
}
.get-started button a{
	color: #fff !important;
}

/* footer Css */

footer {
	background-color: #1B3D9A;
}

footer .general-link h2, .resources-link h2, #feedback h2{
	font-size: 24px;
	line-height: normal;
	color: #fff;
	font-weight: 700;
	margin-bottom: 20px;
}
footer .general-link ul, footer .resources-link ul{
	list-style: none;
	padding: 0;
}
footer .general-link ul li, footer .resources-link ul li{
	font-size: 16px;
	color: #fff;
	line-height: 25px;
	margin-bottom: 10px;
	cursor: pointer;
	text-transform: capitalize!important;
}

.top-footer {
	padding: 25px 0;
	background-color: #163589;
}

.countries-code {
	display: flex;
}

.countries-code .css-b62m3t-container {
	min-width: 110px;
	border-radius: 6px 0px 0px 6px !important;
	padding: 5px 0 0!important;
	background-color: rgb(255 255 255 / 10%) !important;
}
.countries-code .css-b62m3t-container:focus-visible{
	border: none !important;
	box-shadow: none !important;
	outline: none !important;
}
.css-qc6sy-singleValue{
	color: #fff !important;
}

.css-1pahdxg-control {
    background-color: hsla(0, 0%, 100%, 0) !important;
    border-color: #ffffff00 !important;
	box-shadow: none !important;
}

.css-1pahdxg-control:hover,
.css-1pahdxg-control:focus,
.css-1pahdxg-control:focus-visible,
.css-1pahdxg-control:active {
    border-color: transparent !important;
	background-color: transparent !important;
	outline: none !important;
}

.countries-code .phone-number {
	border-radius: 0 6px 6px 0 !important;
	background-color: rgb(255 255 255 / 10%) !important;
	padding-left: 8px !important;
}

.countries-code .form-control:focus {
	box-shadow: none;
}

.css-1s2u09g-control {
	border: 0 !important;
	vertical-align: middle;
	background-color: transparent !important;
	border-width: 0 !important;
}

.contact-info h5,
.contact-info h6 {
	color: #bdbdbd;
	margin-bottom: 15px;
}

.contact-info span,
.contact-info span p {
	color: #fff;
	font-weight: 700;
}

.footer-link {
	margin: 0;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
}

.footer-link li {
	width: fit-content;
	margin: 0 16px;
	text-align: center;
	list-style: none;
}

.footer-link li a {
	color: #fff;
	font-size: 16px;
}

.footer-link li a:hover {
	text-decoration: none;
}


.rights-reserved {
	color: #fff;
	font-size: 15px;
	font-weight: 400;
}


/* Modal Css*/

.modal {
	padding: 0 !important;
	text-align: center;
}

.modal:before {
	content: "";
	height: 100%;
}

.modal .modal-dialog,
.modal:before {
	display: inline-block;
	vertical-align: middle;
}


/* SignUp Modal Css*/

.modal.fade .modal-dialog {
	-moz-transform: none !important;
	-webkit-transform: none !important;
	transform: none !important;
	transition: transform 0.5s ease-out;
}

.signup-modal {
	max-width: 100%;
	margin: 0;
	background-color: #fff;
	min-height: 100vh;
	width: 100%;
	padding: 0;
}

.signup-modal .modal-content {
	border-radius: 0;
	border: 0;
	max-width: 100%;
	/* background-color: #a4d5f3; */
	width: 100%;
	margin: 0 auto;
}

.signup-modal .modal-header {
	align-items: center;
	border: 0;
	padding: 30px 52px 30px;
	position: relative;
	/* margin-bottom: -50px; */
	/* background: linear-gradient(0deg, rgba(27, 61, 154, 0) 74%, rgba(27, 61, 154, 1) 74%, rgba(27, 61, 154, 1) 74%, rgba(27, 61, 154, 1) 100%); */
	background-color: #1a3d9a;
	z-index: 1;
}

.signup-modal .modal-header .close {
	font-size: 32px;
	font-weight: 300;
	color: #1B3D9A;
	padding: 0;
	margin: 0;
	opacity: 1;
	background-color: #ffffff;
	width: 48px;
	height: 48px;
	text-shadow: none;
	border-radius: 50px;
	display: flex;
	z-index: 1;
	position: relative;
}

.signup-modal .modal-header .close>svg {
	margin: auto;
}

.flex-box {
	position: relative;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	/* height: 100vh; */
	margin-top: 210px;
	text-align: center;
}

.flex-box h2 {
	color: #1B3D9A !important;
	font-size: 28px;
}
.flex-box .btn-default:hover{
	transform: inherit;
}
.account-details{
	min-height: 390px;
	position: relative;
}
.new-account-box {
	position: relative;
	border-radius: 30px;
	padding: 24px 24px 24px 24px;
	background-color: #ffffff;
	box-shadow: 0 3px 80px rgb(0 0 0 / 10%);
}

.input_password .custom-close {
	color: grey;
    position: absolute;
    cursor: pointer;
	top: 16px;
    right: 45px;
    font-size: 14px;
    line-height: inherit;
}
.rtl .input_password .custom-close {
	left: 45px;
	right: inherit;
}

.setup-account-title {
	position: absolute;
	top: -80px;
	width: 100%;
	left: 0;
}

.stepAccount h4 {
	color: #fff;
	font-size: 28px;
	font-weight: 400;
	text-align: center;
	margin-bottom: 0;
}

.actionBtn>button {
	border-radius: 4px;
	width: 100%;
	font-size: 16px;
	background-color: #FFDD1C !important;
	color: rgb(0 0 0 / 80%);
	padding: 10px 10px;
	margin-bottom: 16px;
	border: none;
	text-transform: capitalize;
}

.actionBtn button svg {
	width: 30px;
	height: 28px;
	margin: 0 10px;
}

.signup_setup {
	position: absolute;
	/* top: 16px; */
	top: 76px;
	left: 0;
	right: 0;
	width: 100%;
	max-height: 630px;
	height: 100%;
	min-height: 630px;
	background-repeat: no-repeat;
	background-position: top center;
	transition: all 0.2s ease;
}

.shape_one {
	background-image: url(../images/shape-setup1.svg);
}

.shape_two {
	background-image: url(../images/shape-setup2.svg);
}

.have-account {
	color: #333333;
	font-size: 10.5pt;
	font-weight: 400;
}

.have-account span {
	color: #1B3D9A;
	text-decoration: none;
	font-weight: 700;
	cursor: pointer;
}

.contact-number {
	display: flex;
}
.modify-header-button{
	background-color: #1B3D9A!important;
    color: #fff!important;
	border: 2px solid #1B3D9A!important;
}

.sticky .modify-header-button {
	color: #1B3D9A!important;
    border: 2px solid #1B3D9A!important;
    background-color: #fff!important;
}

.contact-number .set-email {
	width: 100%;
}

.label-input {
	font-size: 12.8px;
	color: #626262;
	font-weight: 600;
	top: -10px;
	text-align: left;
	position: absolute;
	left: 8px;
	background-color: #fff;
	width: auto;
	padding: 0 5px;
	width: auto !important;
}

.PhoneInput {
	border: none;
	display: flex;
	padding-right: 55px;
}

.PhoneInputInput {
	border: none;
	margin-left: 10px;
}

.PhoneInput input:focus-visible,
.PhoneInput input:focus,
.PhoneInput input:active {
	border: none !important;
	box-shadow: none !important;
	outline: none;
	-webkit-appearance: none;
}

input.PhoneInputInput:focus {
	outline: none;
}

.PhoneInputCountry {
	margin: 0 8px;
}

.left-text {
	position: absolute;
	left: -128%;
	top: 90px;
	opacity: 0.32;
}

.left-text p {
	color: #ffffff;
	font-size: 44px;
	font-weight: 600;
	text-align: right;
	line-height: normal;
	margin-bottom: 0;
}

.right-text {
	position: absolute;
	right: -128%;
	bottom: 20px;
	opacity: 0.32;
}

.right-text p {
	color: #ffffff;
	font-size: 44px;
	font-weight: 600;
	text-align: left;
	line-height: normal;
	margin-bottom: 0;
}

.check-tp>.MuiButtonBase-root {
	padding: 0;
	margin-right: 4px;
}

.check-tp .MuiCheckbox-colorPrimary.Mui-checked {
	color: #1B3D9A;
}

.check-tp svg {
	width: 22px;
	height: 22px;
}

.terms-contract-sec {
	display: flex;
}

.terms-contract-sec p {
	font-size: 14px;
	color: #333333;
	line-height: 20px;
	margin-bottom: 8px;
	padding-top: 2px;
}
.terms-contract-sec p a{
	color: #1B3D9A;
    font-weight: 500;
}

.signup-modal .agree {
	font-size: 14px;
	color: #333333;
	padding-left: 27px;
	line-height: 20px;
}

.signup-modal .set-email,  .contact-numbers {
	margin-top: 0;
	margin-bottom: 38px;
}
.signup-modal .set-email.site-name-preview p{
	width: inherit; 
	text-overflow: inherit;
    overflow: inherit;
}
.signup-modal .set-email.site-name-preview p:last-child{
	direction: ltr;
	text-overflow: ellipsis;
    overflow: hidden;
}
.signup-modal .set-email.site-name-preview button {
    width: 100%;
    background-color: transparent;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 16px;
    color: #626262;
    font-weight: 400;
    text-align: left;
    padding: 14px;
    display: flex;
    justify-content: space-between;
    align-self: center;
    align-items: center;
}
.signup-modal .set-email.site-name-preview button i{
	font-family: 'Font Awesome 5 Free' !important;
}
.name-preview {
    padding: 7px 8px;
    font-weight: 500;
    border: 1px solid #cccccc;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.name-preview input {
    min-width: unset;
    max-width: 120px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 5px 10px;
	margin-right: 10px;
    box-shadow: inset 0 0 7px rgb(0 0 0 / 10%);
}
.name-preview input svg{
	color: #1B3D9A;
}

.modal-logo {
	max-width: 290px;
	cursor: pointer;
}

.signup-modal .modal-logo {
	width: 100%;
	z-index: 1;
}

.signup-wrap {
	margin: 0;
	width: 100% !important;
	text-align: center;
	flex-direction: column;
	height: 100vh !important;
}

.signUp-box {
	max-width: 410px;
	width: 100%;
}

.setup-account-heading h3 {
	color: #ffffff;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
}

.setup-account-heading p {
	color: #ffffff;
	font-size: 13.5pt;
	font-weight: 400;
	margin-bottom: 0;
	opacity: .6;
}

.signup-wrap h3 {
	color: #424242;
	text-transform: capitalize;
	font-size: 30px;
	margin-bottom: 30px;
}

.modify-modal {
	padding: 0rem 1rem 1rem 1rem !important;
}

.signup-form .form-label {
	text-align: left;
	color: #424242;
	display: block;
	width: fit-content !important;
}

.required {
	color: #908f8f;
	font-size: 12px;
	margin-left: 2px;
}

.signup-wrap p {
	margin-top: 5px;
	color: #424242;
	line-height: 20px;
	font-size: 14px;
	font-weight: 400;
	position: relative;
	float: left;
}

.signup-wrap p.err-msg {
	padding-left: 0;
}

.signup-wrap p span {
	position: absolute;
	left: 0;
}

.signup-wrap p span input {
	line-height: 20px;
	vertical-align: top;
	width: 17px;
	height: 17px;
	display: inline-block;
	cursor: pointer;
}

.agree {
	padding-left: 28px;
}

.signup-wrap a {
	color: #dc3545;
	text-decoration: underline;
}

.signup-wrap .btn {
	width: 100%;
	color: #424242;
	background-color: #ffe256;
	font-size: 20px;
	font-weight: 500;
}

.signup-wrap .input-group-text {
	border-top-right-radius: 0.25rem !important;
	border-bottom-right-radius: 0.25rem !important;
	background-color: #eceeef !important;
}

.signup-form .form-group {
	margin-bottom: 20px;
	margin-top: 5px;
}

.account-create {
	margin-top: 4rem;
}

.account-create svg {
	color: #35b736;
	font-size: 55px;
	margin-bottom: 20px;
}

.account-create h6 {
	color: #231f20;
	font-size: 18px;
	font-weight: 600;
}

.account-create p {
	color: #231f20;
	font-size: 16px;
}

.subscribe-info {
	min-height: 200px;
	text-align: center;
}

.subscribe-info p {
	color: #9e9e9e;
	font-size: 14px;
	font-weight: 300;
	margin-bottom: 8px;
	line-height: normal;
}

.subscribe-btn {
	border-radius: 4px;
	width: 100%;
	font-size: 16px;
	background-color: #ffffff;
	color: #1B3D9A;
	padding: 9px 10px;
	margin-bottom: 5px;
	margin-top: 16px;
	border: none;
}

.subscribe-btn svg {
	width: 1.1em;
	height: 1.1em;
	margin-right: 8px;
	vertical-align: bottom;
}


.bottom-text {
	margin-top: 20px;
	color: #000;
}

.bottom-text a {
	font-weight: 600;
}

.input-spinner {
	position: absolute;
	z-index: 999;
	right: -34px;
	width: 100%;
	top: 50%;
	transform: translate(0, -50%);
}

.success-icon {
	font-size: 5.5rem;
	color: #4bb543;
}

.success-note {
	line-height: 1.3rem;
}
.trusted-partners{
	background-color: #fff;
	border: 1px solid #DEDEDE;
	border-radius: 5px;
	padding: 60px 70px;
}
.trusted-partners h3 {
	font-size: 18px;
	color: #1B3D9A;
	line-height: normal;
	font-weight: 600;
}
.partners-slider .swiper-container{
	margin-top: 42px;
}
.company-partner-box {
	text-align: center;
	background-color: #fff;
	/* box-shadow: 0px 0px 35px #0000001a; */
	border-radius: 6px;
	display: flex;
	align-items: center;
	align-self: center;
	align-content: center;
	cursor: grabbing;
}


.how-work .work-it-title p,
.design-git-card p,
.choose-gitCard-title p {
	font-size: 18px;
	line-height: normal;
	font-weight: 600;
	color: #1B3D9A;
	margin-bottom: 20px;
	text-align: center;
}

.how-work .work-it-title p span,
.design-git-card .work-it-title p span,
.choose-gitCard-title p span {
	height: 1px;
	background-color: #000000;
	width: 30px;
	margin-right: 16px;
}

.rtl .work-it-title h3, .rtl .instant-title h3, .rtl .get-started-title h1, 
.rtl .gifting_digitally h3, .rtl .banner-heading h3, .rtl .banner-heading p,
.rtl .pricing-plans-title h2, .rtl .banner-heading h1 {
	font-family: "DIN Next LT Arabic Bold";
}

.how-work .work-it-title h3,
.choose-gitCard-title h3 {
	max-width: 743px;
	font-size: 36px;
	color: #000000cc;
	line-height: normal;
	font-weight: bold;
	text-align: center;
	margin-bottom: 40px;
	margin-left: auto;
	margin-right: auto;
}

.how-work .work-it-title .btn,
.design-git-card .work-it-title .btn,
.design-git-card .personalized-title .btn {
	font-size: 20px;
	font-weight: 500;
	margin-top: 5px;
	margin-bottom: 0;
	padding: 14px 30px;
}

.how-work .work-it-title {
	margin-bottom: 60px;
}

.how-work .work-step {
	position: relative;
	align-items: center;
	background-color: #fff;
	height: 100%;
	box-shadow: 0px 3px 30px #0000000d;
	/* margin-bottom: 34px; */
	border-radius: 20px;
	cursor: pointer;
}


/* .how-work .work-step:last-child {
  margin-bottom: 0;
} */

.how-work-step {
	/* background-color: #fff;
  box-shadow: 0px 3px 30px #0000000d; */
	border-radius: 20px;
	text-align: center;
	padding: 38px 38px;
	width: 100%;
	position: relative;
	color: #212121;
	transition: 0.3s;
}
.does-it-work{
	counter-reset: number;
	justify-content: center;
}
.how-work-step:before {
    counter-increment: number;
    content: counter(number);
    position: absolute;
    left: 12px;
    font-size: 20px;
    background-color: transparent;
    color: #ada9a9;
    border: 1px solid #ccc;
    width: 32px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    font-weight: 500;
    border-radius: 100%;
    top: 11px;
    z-index: 9;
}
.rtl #howdoeswork .does-it-work .how-work-step::before {
	left: initial;
    right: 12px;
    top: 11px;
}
.how-work .col-sm-6 {
	margin-bottom: 30px;
}

.how-work-step .step-icon {
	max-width: 134px;
	width: 100%;
	height: 134px;
	background-color: #1B3D9A;
	border-radius: 100%;
	overflow: hidden;
	display: flex;
	transition: 0.3s;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 35px;
}

.how-work-step .step-icon img {
	max-width: 50px;
	width: 100%;
	margin: auto;
	color: #ffffff;
}

/* .how-work-step:hover {
	background-color: #241f20;
	color: #ffffff;
}

.how-work-step:hover~.step-number span {
	color: #ffffff10;
} */

.how-work-step h4 {
	font-size: 23px;
	line-height: normal;
	font-weight: 400;
	margin-bottom: 5px;
}

.how-work-step p {
	font-size: 18px;
	line-height: 28px;
	color: #64607D;
	font-weight: normal;
	max-width: 80%;
	margin: 0 auto;
}
.show-m-view {
	width: 100%;
	text-align: center;
	display: block !important;
	padding: 0 15px;
	margin-top: 60px;
}
.free-trial-btn {
	max-width: 200px!important;
    width: 100%!important;
    font-size: 18px!important;
    padding: 16px 24px!important;
    box-shadow: 0px 3px 30px rgb(0 0 0 / 7%)!important;
    margin-bottom: 0!important;
}
.step-number {
	text-align: center;
	border-radius: 100%;
	display: flex;
	position: absolute;
	right: 9px;
	top: 0;
	z-index: 1;
}

.step-number span {
	font-size: 140px;
	font-weight: 500;
	line-height: normal;
	margin: auto;
	color: #241f201a;
}

.design-git-card {
	padding: 80px 0 80px;
}



.rtl .pricing-container .plans:first-child {
	padding-left: 24px;
	padding-right: 0;
}
.rtl .pricing-container .plans:last-child {
	padding-left: 0;
	padding-right: 24px;
}

.dashboard-performance-img {
	max-width: 660px;
	margin-left: auto;
}

.dashboard-analyze {
	align-self: center;
}
.count-number-right{
	position: absolute;
	right: -30px;
	top: 80px;
}

.count-number-left{
	position: absolute;
	left: -30px;
	top: 28% !important;
}
.section-number-left {
	width: 60px;
	height: 60px;
	background-color: #ffee63;
	color: #241f20;
	text-align: center;
	border-radius: 100%;
	display: flex;
	z-index: 1;
}


.design-git-card .work-it-title h3 {
	font-size: 40px;
	line-height: normal;
	font-weight: 900;
	color: #241f20;
	max-width: 354px;
	width: 100%;
	margin-top: 16px;
	margin-bottom: 40px;
}

.personalized-title {
	width: 100%;
	height: 350px;
	margin-left: 100px;
}

.design-git-card .personalized-title h3 {
	font-size: 40px;
	line-height: normal;
	font-weight: 900;
	color: #241f20;
	width: 100%;
	margin-top: 16px;
	margin-bottom: 40px;
}

.personalized-img {
	max-width: 590px;
	margin-left: auto;
	width: 100%;
}

.personalized-img {
	position: relative;
}

.shadow-path {
	position: absolute;
	right: -140px;
	top: 50%;
	max-width: 1024px;
	transform: translateY(-50%);
}

.git-cards {
	margin: 50px -7px 0;
	padding: 0;
}

.design-gift-img {
	max-width: 613px;
}

.instant-notification-title {
	max-width: 400px;
	width: 100%;
	min-height: 600px;
	display: flex;
	margin-left: auto;
	margin-right: 130px;
}

.notification-img {
	max-width: 750px;
}
.instant-title, .instant-list-details{
	max-width: 479px;
	margin-left: auto;
}
.instant-title p {
	font-size: 18px;
	color: #1B3D9A;
	line-height: 22px;
	margin-bottom: 16px;
	font-weight: 600;
}

.instant-title h3 {
	font-size: 36px;
	font-weight: bold;
	color: #000000cc;
	line-height: normal;
	margin-bottom: 40px;
}

.instant-list-details button {
	font-size: 20px;
	font-weight: 500;
	margin-top: 5px;
	margin-bottom: 0;
	padding: 14px 30px;
}

.instant-list-details ul {
	padding-left: 35px;
	margin-bottom: 40px;
}

.instant-list-details ul li {
	font-size: 16px;
	color: #000000cc;
	line-height: 19px;
	font-weight: normal;
	position: relative;
	list-style: none;
	margin-bottom: 16px;
}

.instant-list-details ul li::before {
	content: "";
	position: absolute;
	left: -35px;
	background-image: url(../images/check-icon.svg);
	background-repeat: no-repeat;
	background-size: contain;
	width: 24px;
	height: 24px;
}
.additional-gif-card{
	min-height: 812px;
	display: flex;
}
.additional-gif-card .notification-img{
	min-width: 864px;
	position: absolute;
	left: 0;
}

.additional-gif-card .instant-title h3{
	color: rgb(255 255 255 / 80%);
}
.additional-gif-card .instant-list-details ul li{
	color: rgb(255 255 255 / 80%);
}
.additional-gif-card  .instant-list-details ul li::before {
	background-image: url(../images/check-white-icon.svg);
}
.additional-gif-card .instant-list-details button{
	border: 2px solid #fff;
}

#choose-gitCard .card {
	background: #ffffff;
	box-shadow: 0px 3px 80px #00000012;
	border-radius: 30px;
	border-bottom: 8px solid #A4D5F3;
	padding: 50px 36px 36px;
	margin: 0 15px 8px;
	height: 100%;
}

#choose-gitCard .count-number-right {
	top: 35%;
}

#choose-gitCard .card .choose-icon {
	max-width: 180px;
	margin: 0 auto;
	margin-bottom: 34px;
}

#choose-gitCard .card h3 {
	font-size: 26px;
	font-weight: bold;
	color: #241f20;
	margin-bottom: 30px;
	text-align: center;
}

#choose-gitCard .card p {
	font-size: 20px;
	font-weight: normal;
	color: #626262;
	margin-bottom: 30px;
	text-align: center;
	padding: 0 32px;
	margin-bottom: 0;
}

#choose-gitCard .card .integration-section p {
	padding: 0 24px;
}

#choose-gitCard .card .select_btn {
	max-width: 98px;
	width: 100%;
	font-size: 18px;
	line-height: normal;
	padding: 10px 8px;
	color: #fff;
	font-weight: 400;
	margin-bottom: 0;
	background-color: #1B3D9A;
	border: none;
	margin: 0 auto;
	border-radius: 8px;
	text-align: center;
	cursor: pointer;
	margin-top: auto;
}

#choose-gitCard .card .select_btn button {
	background-color: transparent;
	color: unset;
}

/* feedback */
.feedback-field {
	margin-bottom: 26px;
	position: relative;
}

#feedback .feedback-field .form-control {
	color: #fff;
	font-size: 14px;
	padding: 15px 24px;
	border-radius: 6px;
	border: none;
	background-color: rgb(255 255 255 / 10%);
	height: calc(3em + 2px);
	padding-right: 45px;
}

#feedback .feedback-field label,
#feedback .text-message label {
	color: #fff;
	font-size: 16px;
	font-weight: 400;
}

#feedback .text-message textarea {
	resize: none;
	color: #fff;
	background-color: rgb(255 255 255 / 10%);
	font-size: 16px;
	padding: 22px;
	border-radius: 6px;
	border: none;
}

#feedback .feedback-field input::placeholder,
#feedback textarea::placeholder {
	color: rgb(255 255 255 / 67%);
	font-weight: 400px;
}

#feedback .feedback-field input:-ms-input-placeholder {
	color: #a2a2a2;
}

#feedback .feedback-field input::-ms-input-placeholder {
	color: #a2a2a2;
}

#feedback button {
	margin-top: 34px;
    color: #1B3D9A;
    border: 2px solid #FFDD1C !important;
    background-color: #FFDD1C !important;
}

.custom-close {
	color: grey;
	position: absolute;
	cursor: pointer;
	right: 10px;
	font-size: 14px;
	line-height: inherit;
}

.detail-custom-close {
	color: grey !important;
	position: absolute;
	cursor: pointer;
	right: 35px !important;
	top: 50% !important;
	font-size: 14px;
	line-height: inherit;
	transform: translateY(-50%);
}

.custom-close svg {
	font-size: 26px;
	fill: rgb(255 255 255 / 70%);
}


#faq-gift-card h3 {
	color: #241f20;
	font-size: 40px;
	line-height: 50px;
	font-weight: 900;
	margin-bottom: 14px;
}

/* --------terms-and-conditions--css------- */

.banner-heading {
	background-color: #1B3D9A;
	text-align: center;
	color: #ffffff;
	padding: 60px 20px;
	margin-top: 70px;
}

.banner-heading h1 {
	font-size: 48px;
	line-height: 51px;
	font-weight: 800;
	color: #fff;
}

.banner-heading p {
	color: #fff;
	font-size: 16px;
	font-weight: 500;
}

.terms-topic {
	margin-bottom: 30px;
}

.terms-topic div {
	margin-top: 10px;
	margin-bottom: 15px;
}

.terms-topic>h1 {
	font-size: 29px;
	color: #241f20;
	font-weight: bold;
	border-bottom: 1px solid #000;
	padding-bottom: 8px;
	margin-bottom: 20px;
	display: inline-flex;
}

.terms-conditions .terms-topic>h1 {
	flex-wrap: wrap;
}

.terms-topic div h2 {
	font-size: 20px;
	color: #241f20;
	font-weight: 400;
	margin-bottom: 10px;
	display: inline-flex;
}

.terms-topic div p {
	color: #241f20;
	font-weight: 400;
	margin-bottom: 10px;
	padding-left: 10px;
}

.terms-topic>h1 span,
.terms-topic div h2 span,
.terms-topic div p span {
	margin-right: 7px;
}

.topic-box {
	box-shadow: 0 1px 15px 1px rgb(62 57 107 / 15%);
	padding: 15px;
	position: sticky;
	top: 134px;
}
.topic-box-scroll{
	max-height: 81vh;
	overflow-y: auto;
}
.topic-box-scroll::-webkit-scrollbar{
	display: none;
}

.topic-box h5 {
	font-size: 18px;
	font-weight: 500;
	color: #241f20;
	margin: 16px 0;
}

.topic-box ul {
	padding-left: 5px;
	list-style: none;
}

.topic-box ul li {
	color: #241f20;
	font-weight: 400;
	margin: 20px 4px;
	line-height: 24px;
}

.topic-box ul li a {
	color: #241f20;
	text-decoration: none;
	display: inline-flex;
}

.giftcardsby-terms p {
	color: #241f20;
}

.giftcardsby-terms p span {
	color: #241f20;
	font-size: 17px;
	font-weight: 600;
}

.giftcardsby-terms p a {
	color: #838562 !important;
	font-size: 17px;
	text-decoration: underline;
	font-weight: 600;
}

.topic-box ul li:last-child {
	margin-bottom: 0;
}

.topic-box ul li span {
	margin-right: 5px;
}

.contact-content {
	padding: 0;
	min-height: calc(100vh - 152px);
}

.contact-heading {
	background-color: #fff;
	/* text-align: center; */
	color: #ffffff;
	padding: 60px 0;
	margin-top: 92px;
}

.contact-heading h1 {
	font-size: 48px;
	line-height: 51px;
	font-weight: 800;
	color: #241f20;
}

.contact-heading p {
	color: #241f20;
	font-size: 20px;
	font-weight: 500;
}

.contacts-infos {
	background-color: #ffe256;
	border-radius: 8px;
	padding: 30px 20px 20px 25px;
	padding-bottom: 1px;
	margin-bottom: 20px;
}

.contact-content .contact-info-from textarea {
	resize: none;
}

.contact-content .contact-info-from .form-control {
	border: 2px solid #ddd;
	border-radius: 5px;
}

.contact-content .contact-info-from label {
	color: #424242;
}

.contact-info-from {
	margin-bottom: 16px;
}

.contact-content button {
	color: #fff;
	font-size: 20px;
	margin: 10px 0;
}


.contacts-infos h3 {
	font-size: 24px;
	color: #241f20;
	font-weight: 500;
}

.contacts-infos h4 {
	font-size: 15px;
	color: #424242;
	font-weight: 400;
	line-height: 22px;
	margin-bottom: 0;
}

.contacts-infos i {
	font-size: 21px;
	color: #241f20;
}

.info-icon {
	display: flex;
	align-items: center;
	padding: 0 8px;
	margin: 40px 0;
}

.info-icon span {
	margin-right: 25px;
	min-width: 20px;
	text-align: center;
}

.info-icon span .fa-phone {
	transform: rotate(90deg);
}

.fa,
.fas {
	font-family: "Font Awesome 5 Free" !important;
	font-weight: 900;
}

.error-msg {
	font-size: 14px !important;
	padding-left: 4px;
}

.comman_title {
	margin-bottom: 40px;
}

.comman_title label {
	position: relative;
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 0px;
	color: #1B3D9A;
	/* padding-left: 44px; */
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 12px;
}

.comman_title h3 {
	font-size: 36px;
	font-weight: bold;
	letter-spacing: 0px;
	color: #000000cc;
	margin-bottom: 10px;
}

.testimonial_wrapper {
	position: relative;
}

.testimonial_wrapper .swiper-container {
	/* box-shadow: 0px 0px 20px #0000001a; */
	max-height: 200px;
}

.testimonial_pagination {
	position: absolute;
	top: 25%;
	transform: translateY(0);
	left: 29.5%;
	z-index: 1;
}
.rtl .testimonial_pagination{
	left: inherit;
	right: 29.5%;
}

.testimonial_image {
	width: 100%;
	text-align: right;
	padding-right: 77px;
}
.rtl .testimonial_image {
	/* text-align: left; */
	padding-right: inherit;
	padding-left: 77px;
}

.testimonial_content {
	max-width: 600px;
	margin-left: 30px;
}


.testimonial_content .testimonial_words p {
	font-size: 16px;
	font-weight: normal;
	color: #000000b3;
	line-height: 24px;
	display: block;
	margin-bottom: 20px;
}

.testimonial_content .testimonial_words span {
	display: block;
	width: 100%;
	border: 1px dashed #707070;
	opacity: 0.6;
	border-left: 0;
	border-right: 0;
	height: 5px;
}


.testimonial_content .testimonial_info h5 {
    margin-bottom: 3px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #000;
}

.testimonial_content .testimonial_info label {
	margin-bottom: 0;
	font-size: 14px;
	font-weight: normal;
	letter-spacing: 0px;
	color: #757095;
}

.testimonial_pagination .swiper-pagination-h .swiper-pagination-bullet {
	display: block;
	width: 15px;
	height: 15px;
	border: 1px solid #1B3D9A;
	background: #fff;
	margin-bottom: 16px;
	opacity: 1;
}
.testimonial_pagination::after {
    position: absolute;
    content: '';
    top: -16px;
    right: 6.3px;
    height: calc(100% + 20px);
    width: 2px;
    background-color: #0000001a;
	z-index: -1;
}

.testimonial_pagination .swiper-pagination-h .swiper-pagination-bullet-active {
	background: #1B3D9A;
}

.selling-cards-number {
	position: relative;
	z-index: 1;
}

.gifting_digitally h3 {
    font-size: 36px;
    font-weight: bold;
    color: #000000cc;
    line-height: 48px;
    margin-bottom: 40px;
	text-align: center;
	max-width: 465px;
	margin-left: auto;
	margin-right: auto;
}

.customer-card-selling {
	/* display: flex; */
	padding: 38px;
	border-radius: 12px;
	color: #000000cc;
	background-color: #ffffff;
	transition: 0.3s;
	/* min-height: 295px; */
	box-shadow: 0px 3px 30px #0000000d;
	height: 100%;
}


.customer-card-selling small {
	font-size: 22px;
	line-height: normal;
}

.customer-card-selling h2 {
	font-size: 36px;
    font-weight: bold;
    color: #000000cc;
    line-height: 48px;
    margin: 30px 0px;
}

.customer-card-selling p {
	font-size: 16px;
	color: #000000b8;
	line-height: 24px;
	margin-bottom: 0;
}

.spinner-loaderIcon {
	width: 1.1rem;
	height: 1.1rem;
	margin-right: 8px;
}
.contact-number .country_box{
	margin-right: 14px;
	min-width: 110px;
}
.contact-number .country_box .MuiSelect-outlined{
	padding-right: 28px !important;
}


.testimonal_section{
	padding: 100px 0 110px;
}
.how-work{
	padding: 250px 0 130px;
	margin-top: -110px;
}
#notification-email{
	padding: 140px 0 200px;
}
#performance-gitCard{
	padding: 200px 0 0;
}
#performance-gitCard .instant-title, #performance-gitCard .instant-list-details{
	margin-left: 0;
}
.dashboard-performance{
	padding: 130px 0;
}
.dashboard-performance .instant-title, .dashboard-performance .instant-list-details {
margin-left: auto !important;
}
.rtl .dashboard-performance .instant-title, .rtl .dashboard-performance .instant-list-details {
margin-left: 0 !important;
margin-right: auto !important;
}
.gifting_digitally {
	padding: 180px 0 200px;
	background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(244,248,255,1) 100%);
}
footer{
	padding: 60px 0 0;
}
.top-footer {
    padding: 25px 0;
    background-color: #163589;
    margin-top: 60px;
}

/* pricing-plans */
.pricing-plans .banner-heading{
	background-color: transparent;
	margin-top: 69px;
}
.pricing-plans .banner-heading p{
	color: rgb(0 0 0 / 70%);
	font-size: 32px;
	margin-top: 30px;
}
.pricing-plans .banner-heading h3{
	color:#1B3D9A;
	font-size: 52px;
	font-weight: 500;
}
.pricing-container{
	margin-top: 70px;	
	padding-bottom: 110px;
}
.pricing-container .plans{
	cursor: pointer;
}
.pricing-container .plans:first-child{
	padding-right: 24px;
	padding-left: 0;
}
.pricing-container .plans:last-child {
	padding-right: 0;
	padding-left: 24px;
}

.pricing-box{
	border-radius: 20px;
	padding: 40px 25px;
	background-color: #fff;
	box-shadow: 0 3px 80px rgb(0 0 0 / 1%);
	height: 100%;
}
.pricing-box.premium-plans{
	transform: scale(1.1);
	background-color: #1B3D9A;
}
.pricing-box .pricing-plans-title h2{
	font-size: 40px;
    line-height: normal;
    color: rgb(0 0 0 / 80%);
    margin-bottom: 18px;
    font-weight: 500;
}
.pricing-box .pricing-plans-title p{
	font-size: 18px;
    color: rgb(0 0 0 / 80%);
    font-weight: normal;
    margin-bottom: 36px;
}
.amout-validation h3{
	font-size: 32px;
    color: #000;
    font-weight: 400;
	margin-bottom: 10px;
}
.amout-validation p{
	font-size: 17px;
	color: rgb(0 0 0 / 70%);
	font-weight: 400;
	margin-bottom: 0px;
}
.amout-validation p b{
	color: rgb(0, 0, 0);
	font-weight: 500 !important;
}
.amout-validation p span{
	font-size: 14px;
	color: #2B4FB4; 
}
.amout-validation h6{
	font-size: 14px;
	color: rgb(0 0 0 / 80%);
	margin-top: 30px;
	font-weight: normal;
}
.pricing-box button{
	font-size: 18px;
	margin: 35px 0 50px;
	font-weight: 400;
}
.plant-benefits li {
    list-style: none;
    font-size: 16px;
    color: rgb(0 0 0 / 80%);
    margin-bottom: 10px;
    display: flex;
}
.contact-link {
	color: #1B3D9A;
	text-decoration: none;
}
.contact-link:hover {
	text-decoration: none;
}
.pricing-box .premium-plans .contact-link {
	color: black;
    text-decoration: none;
}
.plant-benefits li svg{
	color: #1B3D9A;
    font-size: 21px;
	margin-right: 8px;
}
.rtl .plant-benefits li svg{
	margin: 0 0px 0 8px;
	transform: rotateY(0deg);
}
.pricing-box h4 {
    font-size: 20px;
    color: #1B3D9A;
    font-weight: 500;
    margin: 30px 0 0;
}
.premium-plans .pricing-plans-title small{
	font-size: 20px;
	font-weight: bold;
	color: #FFDD1C !important;
}
.premium-plans .plant-benefits li, .premium-plans .plant-benefits li svg,.premium-plans .pricing-plans-title h2, .premium-plans .pricing-plans-title p, .premium-plans .amout-validation h3, .premium-plans .amout-validation p, .premium-plans .amout-validation p b, .premium-plans .amout-validation h6{
    color: #ffffff;
}
.premium-plans .amout-validation span{
	color: #FFDD1C;
}
.premium-plans.pricing-box button {
	background-color: #FFDD1C;
	color: #1B3D9A;
}
.client-testimonial .client-testimonial-box img{
	max-width: 80%;
}
/* Right-to-left  direction  */
.rtl .navbar-brand{
	margin-left: auto !important;
	margin-right: 0 !important;
	/* transform: rotateY(180deg); */
}
.rtl .custom-select-dropdown:after{
	right: inherit;
	left: 0;
}
.rtl .header-btn button + button{
	margin-right: 10px;
	margin-left: 0px;
}
 .rtl .partners-slider .swiper-container {
	direction: ltr;
}

.rtl .additional-gif-card .notification-img{
	left: inherit;
	right: 0;
}
 
.rtl .branded-gift-img .people-card-img {
    margin-right: -70px;
    margin-left: inherit;
}
.rtl .banner-info {
	font-family: "DIN Next LT Arabic Bold";
}
.rtl .banner-info h3{
	font-family: "DIN Next LT Arabic Bold";
}
.rtl .banner-info .btn-default{
	margin-right: 0;
}
.rtl .banner-info .btn-default svg {
    margin-right: 10px;
    transform: rotateY(180deg);
}

.rtl .testimonial_wrapper {
    margin-left: inherit;
}
.rtl .actionBtn button svg{
    transform: rotateY(180deg);
}
.rtl .check-aerrow-all{
    transform: inherit !important;
}
.rtl .step-number {
    left: 9px;
    right: inherit;
}
.rtl .count-number-right{
	right: inherit;
	left: -30px;
}
 /* .rtl .notification-img img{
	transform: rotateY(180deg);
} */
.rtl .faq-section-img .faq-images{
	text-align: left !important;
}
.rtl .count-number-left {
	right: -30px;
	left: inherit;
}
.rtl .personalized-title {
    margin-right: 100px;
    margin-left: 0;
}

.rtl .dashboard-performance-img {
    margin-right: auto;
    margin-left: inherit;
}

.rtl #feedback .feedback-field .form-control {
    padding-left: 45px;
    padding-right: 20px;
}
.rtl .custom-close {
    left: 17px;
    right: inherit;
}
.rtl .countries-code .css-b62m3t-container {
    border-radius: 0 6px 6px 0 !important;
}
.rtl .countries-code .phone-number {
    border-radius: 6px 0 0 6px !important;
    padding-right: 8px !important;
    padding-left: 45px !important;
}
.rtl .signUp-box .MuiStepConnector-alternativeLabel {
		left: calc(50% + 20px);
		right: calc(-50% + 20px);
}
.rtl .PhoneInput {
	padding-left: 55px;
	padding-right: 12px;
}
.rtl .PhoneInputInput {
    margin-left: 10px;
    margin-right: 0;
}
.rtl .detail-custom-close{
	left: 35px !important;
	right: inherit !important;
}
.rtl .signup-modal .agree{
	padding-right: 27px;
	padding-left: 0;
}
.rtl .label-input{
	right: 8px;
	left: inherit;
}
.rtl .language-select{
    padding-left: 0;
}
.rtl .flex-box .text-danger , .rtl .banner-email .text-danger{
	text-align: right !important;
}
.rtl .shop-img{
	text-align: left !important;
}
.rtl .terms-conditions , .rtl .privacy-policy{
	direction: ltr;
	text-align: left;
}
.rtl .instant-title, .rtl .instant-list-details{
	margin-left: inherit;
	margin-right: auto;
}
.rtl #performance-gitCard .instant-title,.rtl #performance-gitCard .instant-list-details{
	margin-right: inherit;
	margin-left: auto;
}
.rtl .instant-title ul, .rtl .instant-list-details ul{
	padding-left: 0 !important;
	padding-right: 34px !important;
}
.rtl .instant-title ul li::before, .rtl .instant-list-details ul li::before{
	right: -35px;
	left: inherit;
	transform: rotateY(0deg);
}
.rtl .testimonial_content{
	direction: rtl;
}
.rtl .contact-number .country_box{
	margin-left: 14px;
	margin-right: 0;
	min-width: 110px;
}
.rtl .contact-number .country_box .MuiSelect-outlined{
	padding-left: 28px !important;
	padding-right: 14px !important;
}

/* new pricing page */
.pricingswitchmain{
	border: 2px solid #1B3D9A;
	border-radius: 25px;
	width: 250px;
}
.activeSwitch{
	background-color: #1B3D9A;
}
.pricingswitch{
	padding: 10px 20px;
	margin: 5px 5px;
	border-radius: 20px;
	width: 50%;
	color: #1B3D9A;
	cursor: pointer;
}
.popularplan{
	width: 100%;
	color: #FFDD1C !important;
	height: 20px;
}
.pricingtable{
	background-color: white;
	border-bottom: 1px solid rgba(0,0,0,0.2);
}
.pricingtable .pricingbox{
	border-left: 1px solid rgba(0,0,0,0.2);
	border-top: 1px solid rgba(0,0,0,0.2);
	border-right: 0;
}
.pricingtable .pricingbox:last-child{
	border-right: 1px solid rgba(0,0,0,0.2);
}
.pricingtable .border-pricing{
	border: 1px solid rgba(0,0,0,0.2);
	border-bottom: 0;
}
.pricingtable .pricing-plans-title h2, h5{
	color: rgba(0,0,0,0.7);
}
.text-red{
	color: red;
}
.pricingswitchmain1{
	border: 2px solid #1B3D9A;
	border-radius: 25px;
	width: 100px;
}
.pricingcurrency{
	background-color: transparent;
	border: 0;
	color: #163589;
	padding: 14px 20px;
}
.pricingcurrency:focus-visible{
	outline: none !important;
}
.fs1{
	font-size: 45px;
	font-weight: bold;
}
.fs2{
	font-weight: bold;
	font-size: 25px;
}
.pricingtable .firstprtabletop{
	border-top: 2px solid #163589 !important;
	border-left: 2px solid #163589 !important;
	border-right: 2px solid #163589 !important;
}
.pricingtable .firstprtable{
	border-left: 2px solid #163589 !important;
	border-right: 2px solid #163589 !important;
}
.pricingtable .firstprlast{
	border-bottom: 2px solid #163589 !important;
	border-left: 2px solid #163589 !important;
	border-right: 2px solid #163589 !important;
}
.visible-md{
	display: none;
}
.visible-sd{
	display: none;
}
@media screen and (min-width:1200px){
	.visible-md{
		display: block;
	}
}
@media screen and (max-width:1200px){
	.visible-sd{
		display: block;
	}
	.fixed-sd{
		position: sticky;
		top:5%;
		background-color: #F4F8FF !important;
		padding-bottom: 20px;
	}
}
.rounded-2{
	border-radius: 10px;
}
.selectedPlan{
	border: 2px solid #163589;
}